<template>
  <svg width="150" height="27" viewBox="0 0 178 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.2954 24.3936H1.57406H1.31177L1.57406 28.0657L3.93472 30.4263H23.8691L22.2954 24.3936Z" fill="#111827"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91803 5.5082C8.91803 3.91472 10.2098 2.62295 11.8033 2.62295H21.7705V0H11.8033C8.76118 0 6.29508 2.4661 6.29508 5.5082V23.082H1.31148C0.587168 23.082 0 23.6691 0 24.3934V26.4918C0 29.5339 2.4661 32 5.50819 32H9.18033H14.6885H26.4918C29.5339 32 32 29.5339 32 26.4918V10.2295H29.377V26.4918C29.377 28.0853 28.0853 29.377 26.4918 29.377C24.8983 29.377 23.6066 28.0853 23.6066 26.4918V24.3934C23.6066 23.6691 23.0194 23.082 22.2951 23.082H8.91803V5.5082ZM20.9836 25.7049H8.91803H6.29508H2.62295V26.4918C2.62295 28.0853 3.91472 29.377 5.50819 29.377H6.30684H9.18033H11.8033H14.6885H21.7988C21.2818 28.5379 20.9836 27.5497 20.9836 26.4918V25.7049ZM13.1147 7.08197C12.3904 7.08197 11.8033 7.66913 11.8033 8.39344C11.8033 9.11775 12.3904 9.70492 13.1147 9.70492H16.7869C17.5112 9.70492 18.0984 9.11775 18.0984 8.39344C18.0984 7.66913 17.5112 7.08197 16.7869 7.08197H13.1147ZM13.1147 12.3279C12.3904 12.3279 11.8033 12.915 11.8033 13.6393C11.8033 14.3637 12.3904 14.9508 13.1147 14.9508H22.0328C22.7571 14.9508 23.3443 14.3637 23.3443 13.6393C23.3443 12.915 22.7571 12.3279 22.0328 12.3279H13.1147ZM11.8033 18.8852C11.8033 18.1609 12.3904 17.5738 13.1147 17.5738H25.1803C25.9046 17.5738 26.4918 18.1609 26.4918 18.8852C26.4918 19.6096 25.9046 20.1967 25.1803 20.1967H13.1147C12.3904 20.1967 11.8033 19.6096 11.8033 18.8852Z" fill="#6267EC"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3771 7.60656H32V0H24.3935V2.62295H27.8033L23.8689 6.55737L25.7236 8.41208L29.3771 4.75858V7.60656Z" fill="#6267EC"/>
  <path d="M49.3596 12.5948H51.4667C51.4031 10.2756 49.3526 8.59276 46.4111 8.59276C43.5051 8.59276 41.2778 10.2544 41.2778 12.7503C41.2778 14.7655 42.7202 15.9463 45.0465 16.5756L46.7576 17.0423C48.3061 17.4524 49.501 17.9614 49.501 19.2483C49.501 20.6625 48.1505 21.5958 46.2909 21.5958C44.6081 21.5958 43.2081 20.8463 43.0808 19.2695H40.8889C41.0303 21.8928 43.0596 23.5119 46.3051 23.5119C49.7061 23.5119 51.6647 21.7231 51.6647 19.2695C51.6647 16.6604 49.3384 15.6493 47.5 15.1968L46.0859 14.8291C44.9546 14.5392 43.4485 14.0089 43.4556 12.6231C43.4556 11.3928 44.5798 10.4806 46.3616 10.4806C48.0233 10.4806 49.2041 11.2584 49.3596 12.5948Z" fill="white"/>
  <path d="M53.3947 23.2715H55.5089V12.4109H53.3947V23.2715ZM54.4624 10.7352C55.1907 10.7352 55.7988 10.1695 55.7988 9.47659C55.7988 8.78366 55.1907 8.21094 54.4624 8.21094C53.727 8.21094 53.126 8.78366 53.126 9.47659C53.126 10.1695 53.727 10.7352 54.4624 10.7352Z" fill="white"/>
  <path d="M57.6419 23.2715H59.7561V16.5756C59.7561 15.1119 60.7813 14.0938 61.9834 14.0938C63.1571 14.0938 63.9702 14.8715 63.9702 16.0524V23.2715H66.0773V16.3493C66.0773 15.0412 66.8763 14.0938 68.2551 14.0938C69.3722 14.0938 70.2914 14.716 70.2914 16.1867V23.2715H72.4056V15.9887C72.4056 13.5069 71.0197 12.2695 69.0541 12.2695C67.4914 12.2695 66.3177 13.019 65.7945 14.1786H65.6813C65.2076 12.9907 64.2106 12.2695 62.7611 12.2695C61.3258 12.2695 60.2581 12.9837 59.8056 14.1786H59.6712V12.4109H57.6419V23.2715Z" fill="white"/>
  <path d="M74.5281 27.3443H76.6422V21.5816H76.7695C77.1513 22.2746 77.9291 23.4837 79.9089 23.4837C82.5392 23.4837 84.4483 21.3766 84.4483 17.8625C84.4483 14.3412 82.5109 12.2695 79.8877 12.2695C77.8725 12.2695 77.1442 13.4998 76.7695 14.1715H76.5927V12.4109H74.5281V27.3443ZM76.5998 17.8412C76.5998 15.5715 77.5897 14.0655 79.4351 14.0655C81.3513 14.0655 82.3129 15.6847 82.3129 17.8412C82.3129 20.019 81.323 21.6806 79.4351 21.6806C77.618 21.6806 76.5998 20.1251 76.5998 17.8412Z" fill="white"/>
  <path d="M88.22 8.79074H86.1058V23.2715H88.22V8.79074Z" fill="white"/>
  <path d="M95.0339 23.4907C97.4025 23.4907 99.0783 22.3241 99.5591 20.5564L97.5581 20.1958C97.1763 21.221 96.2571 21.7443 95.0551 21.7443C93.245 21.7443 92.0288 20.5705 91.9722 18.4776H99.6935V17.7281C99.6935 13.8039 97.346 12.2695 94.8854 12.2695C91.8591 12.2695 89.8652 14.5746 89.8652 17.9119C89.8652 21.2847 91.8308 23.4907 95.0339 23.4907ZM91.9793 16.8938C92.0642 15.3524 93.1813 14.016 94.8995 14.016C96.5399 14.016 97.6147 15.2321 97.6217 16.8938H91.9793Z" fill="white"/>
  <path d="M103.661 23.2715L104.736 19.9695H109.961L111.036 23.2715H114.317L109.325 8.79074H105.38L100.381 23.2715H103.661ZM105.514 17.5796L107.296 12.0998H107.409L109.191 17.5796H105.514Z" fill="white"/>
  <path d="M115.349 23.2715H118.41V18.5766H120.984C124.314 18.5766 126.252 16.5897 126.252 13.6978C126.252 10.82 124.35 8.79074 121.062 8.79074H115.349V23.2715ZM118.41 16.1231V11.2938H120.475C122.243 11.2938 123.098 12.2554 123.098 13.6978C123.098 15.1332 122.243 16.1231 120.489 16.1231H118.41Z" fill="white"/>
  <path d="M130.591 8.79074H127.529V23.2715H130.591V8.79074Z" fill="white"/>
  <path d="M132.731 23.2715H134.916V16.9574H141.067V15.0837H134.916V10.6715H141.711V8.79074H132.731V23.2715Z" fill="white"/>
  <path d="M147.294 23.4907C150.356 23.4907 152.357 21.2493 152.357 17.8907C152.357 14.5109 150.356 12.2695 147.294 12.2695C144.233 12.2695 142.232 14.5109 142.232 17.8907C142.232 21.2493 144.233 23.4907 147.294 23.4907ZM147.302 21.716C145.301 21.716 144.367 19.9695 144.367 17.8837C144.367 15.8049 145.301 14.0372 147.302 14.0372C149.288 14.0372 150.222 15.8049 150.222 17.8837C150.222 19.9695 149.288 21.716 147.302 21.716Z" fill="white"/>
  <path d="M154.006 23.2715H156.12V16.6392C156.12 15.218 157.216 14.1928 158.715 14.1928C159.153 14.1928 159.648 14.2705 159.818 14.32V12.2978C159.606 12.2695 159.189 12.2483 158.92 12.2483C157.647 12.2483 156.558 12.9695 156.162 14.1362H156.049V12.4109H154.006V23.2715Z" fill="white"/>
  <path d="M160.936 23.2715H163.051V16.5756C163.051 15.1119 164.076 14.0938 165.278 14.0938C166.452 14.0938 167.265 14.8715 167.265 16.0524V23.2715H169.372V16.3493C169.372 15.0412 170.171 14.0938 171.55 14.0938C172.667 14.0938 173.586 14.716 173.586 16.1867V23.2715H175.7V15.9887C175.7 13.5069 174.314 12.2695 172.348 12.2695C170.786 12.2695 169.612 13.019 169.089 14.1786H168.976C168.502 12.9907 167.505 12.2695 166.056 12.2695C164.62 12.2695 163.553 12.9837 163.1 14.1786H162.966V12.4109H160.936V23.2715Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "LogoInverse"
};
</script>
import namespace from '../namespace';

export default namespace('user', {
  getters: [
    'getToken',
    'getUser',
    'getPlan',
    'isPremium',
    'name',
    'email',
    'currentPassword',
    'newPassword',
    'confirmPassword',
    'sendToAlternativeBillingEmail',
    'alternativeBillingEmail',
    'paymentMethods'
  ],
  mutations: [
    'login',
    'logout',
    'setUser',
    'setPlan',
    'setToken',
    'setName',
    'setEmail',
    'setCurrentPassword',
    'setNewPassword',
    'setConfirmPassword',
    'setSendToAlternativeBillingEmail',
    'setAlternativeBillingEmail',
    'setPaymentMethods'
  ],
  actions: [
    'setupAxios',
    'login',
    'register',
    'logout',
    'forgot',
    'reset',
    'updateUser',
    'updatePassword',
    'updateBillingInformation',
    'addPaymentMethod',
    'listPaymentMethods',
    'resetData',
    'activate'
  ],
});

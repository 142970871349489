<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-12 px-4">
    <LogoIcon class="mb-8"/>
    <div v-if="!send" class="max-w-md w-full border border-gray-200 pt-12 pb-16 px-12 rounded bg-white filter drop-shadow-lg">
      <div>
        <h2 class="text-center text-2xl font-bold text-gray-800 mb-2">
          {{$t('forgot.title')}}
        </h2>
        <p class="text-center text-sm text-gray-900">
          {{$t('forgot.subtitle')}}
        </p>
      </div>
      <form 
        novalidate 
        class="mt-8 space-y-6"
        @submit.stop.prevent="handleSubmit"
      >
        <div class="rounded-md">         
          <div class="py-2">
            <label for="email" class="sr-only">{{$t('forgot.form.email')}}</label>
            <input 
              v-model.trim="form.email" 
              :state="validateState('email')"
              id="email"
              name="email" 
              type="email" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.email.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('forgot.form.email')"
            >
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.email.$error">
              <div v-if="!$v.form.email.required">{{$t('forgot.form.emailRequired')}}</div>                            
              <div v-if="!$v.form.email.email">{{$t('forgot.form.emailFormat')}}</div>
            </div>
          </div>
        </div>

        <div>
          <button 
            type="submit" 
            class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
           <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>            
           </span>
            {{$t('forgot.form.submit')}}
          </button>
        </div>
      </form>
    </div>
    <div class="max-w-xs w-full space-y-8" v-if="send">
      <div>
        <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {{$t('forgot.title2')}}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{$t('forgot.subtitle2')}}
          <a href="/forgot" class="font-semibold text-indigo-500 hover:text-indigo-600">
          {{$t('forgot.resend')}}
          </a>.
        </p>
      </div>      
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import { required, email } from 'vuelidate/lib/validators'
import UserType from '@/store/types/user';
import LogoIcon from '@/components/icons/Logo';

export default {
  name: 'Forgot',
  components: {
    LogoIcon
  },  
  data() {
    return {    
      send: false,  
      form: {
        email: ''
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validateState(field) {
      const { $dirty, $error } = this.$v.form[field];
      return $dirty ? !$error : null;
    },    
    async handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }
      
      try {
        await this.$store.dispatch(UserType.actions.forgot, this.$data.form);
        this.$data.send = true;
      } catch (err) {
        Vue.$toast.error(err.message);
        this.$data.send = false;
      }
    }
  },
};
</script>

<template>
  <div class="w-full px-6 border-b flex items-center justify-between mb-6">
    <!-- left navbar -->
    <div class="flex">
      <!-- mobile hamburger -->
      <div class="lg:hidden flex items-center mr-4">
        <button
          class="hover:text-blue-500 hover:border-white focus:outline-none navbar-burger"
          @click="toggleSidebar()"
        >
          <svg
            class="h-5 w-5"
            v-bind:style="{ fill: 'black' }"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menú</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div class="flex flex-col">
        <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {{title}}
        </h1>
        <span class="text-sm">{{subtitle}}</span>
      </div>
    </div>
    <div>
      <div @click="toggleDropdown()" class="flex p-4 pr-0 items-center cursor-pointer">
        <div class="m-1 ml-2 w-8 h-8 relative flex justify-center items-center rounded-full bg-indigo-500 text-sm text-white uppercase">
          {{name}}        
        </div>
        <p class="pl-1 font-semibold">{{user.name}}</p>
        <svg class="h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </div>
      <div v-if="dropDownOpen" @click="toggleDropdown()" class="absolute inset-0 h-full w-full z-10"></div>
      <div v-if="dropDownOpen" class="text-sm absolute top-16 right-0 mr-8 w-48 bg-white rounded-md border border-gray-300 shadow-xl z-20">
        <router-link 
          to="/users/settings/profile"
          class="w-full flex items-center py-2 hover:bg-blue-500 hover:bg-opacity-20 px-4"
        >
          <span >{{$t('dashboard.sidebar.account.profile')}}</span>
        </router-link>
        <a href="#" @click="logout" class="w-full flex items-center py-2 hover:bg-blue-500 hover:bg-opacity-20 px-4">
          <span >{{$t('dashboard.sidebar.account.logout')}}</span>
        </a>        
      </div>           
    </div>        
  </div>
</template>

<script>

import UserType from "@/store/types/user";
import { mapGetters } from 'vuex';

export default {
  name: "Navbar", 
  props: ['title', 'subtitle'], 
  computed: {
    sideBarOpen() {
      return this.$store.getters.sideBarOpen;
    },
    ...mapGetters({
      user: UserType.getters.getUser
    }),
    name() {
      const t = this.user.name.split(' ').reduce((a,b) => {
        return a.concat(b.charAt(0))
      },'');

      return t.length > 2 ? t.substring(0,2) : t;
    }    
  },
  data() {
    return {
      dropDownOpen: false
    };
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar");
    },
    toggleDropdown() {
      this.dropDownOpen = !this.dropDownOpen
    },
    async logout() {
      try {
        await this.$store.dispatch(UserType.actions.logout);
        this.$router.push({ name: "login" });
        this.$store.dispatch(UserType.actions.resetData);
      } catch (err) {
        this.$bvToast.toast(err.message);
      }
    }    
  }
};
</script>

import FormType from '../../types/forms/list';
import injector from 'vue-inject';

const state = {
  newForm: null,
  forms: null
}

const getters = {
  [FormType.getters.getForms](state) {
    return state.forms;
  },
  [FormType.getters.getNewForm](state) {
    return state.newForm;
  }
}

const mutations = {
  [FormType.mutations.setForms](state, forms) {
    state.forms = forms;
  },
  [FormType.mutations.setForm](state, form) {
    state.newForm = form;
  }
}

const actions = {
  [FormType.actions.listAll]: injector.encase(['FormService'], (FormService) => async ({ commit }) => {
    try {
      
      const { forms } = await FormService.findAll();
      commit(FormType.mutations.setForms, forms);      
      return forms;

    } catch (error) {
      commit(FormType.mutations.setForms, null);
      throw error;
    }
  }),
  [FormType.actions.create]: injector.encase(['FormService'], (FormService) => async ({ commit }, formData) => {
    try {
      const { form } = await FormService.create(formData);
      commit(FormType.mutations.setForm, form);
      
      return form;

    } catch (error) {
      commit(FormType.mutations.setForm, null);
      throw error;
    }
  }),
}

export default {
  state,
  getters,
  mutations,
  actions,
};
<template>
  <div>
    <Navbar :title="$t('dashboard.sidebar.workspaces.myForms')"/>
    <div class="flex flex-col flex-grow px-6">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="flex-1 min-w-0">
        </div>
        <div class="mt-5 flex lg:mt-0 lg:ml-4">
          <button 
            @click="$modal.show('newForm')"
            class="inline-flex items-center px-4 py-2 border border-transparent rounded shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{$t('form.list.create')}}
          </button>
        </div>
      </div>
      <div class="flex items-center" :class="formList && formList.length === 0 ? 'justify-center flex-grow flex-col' : 'justify-end mb-6'">
        <p v-if="formList.length === 0" class="mb-3">You don't have any form yet</p>
      </div>

      <div v-if="formList.length">
        <table class="w-full divide-y divide-gray-200 table-auto border border-gray-300 rounded-md">
          <thead class="bg-gray-50 border-b border-gray-300">
            <tr>            
              <th scope="col" class="whitespace-nowrap px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ $t('form.list.headers.name') }}
              </th>
              <th scope="col" class="w-48 whitespace-nowrap px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ $t('form.list.headers.responses') }}
              </th>        
              <th scope="col" class="w-48 whitespace-nowrap px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ $t('form.list.headers.active') }}
              </th>   
              <th scope="col" class="w-48 whitespace-nowrap px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ $t('form.list.headers.creationDate') }}
              </th>               
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="data in formList" v-bind:key="data.form.slug" class="hover:bg-gray-100">
              <td class="px-4 py-2">
                <router-link 
                  :to="{name:'responses', params: { slug: data.form.slug, page: 1 }}"
                  style="min-width: 150px" 
                  class="w-full text-sm text-gray-900"
                >            
                  <div class="font-bold">{{ data.form.name }}</div>
                  <div class="text-xs">ID: {{data.form.slug}}</div>
                </router-link>
              </td>      
              <td class="px-4 py-2">
                <div style="min-width: 150px" class="w-full text-sm text-gray-900">
                  {{ $tc('form.list.submissions', data.statistics.submissions, { count: data.statistics.submissions}) }}
                </div>
              </td>
              <td class="px-4 py-2">
                <div style="min-width: 150px">
                  <div 
                    style="padding-top: 0.2em; padding-bottom: 0.2rem" 
                    class="w-20 flex items-center justify-center space-x-1 text-xs px-2 bg-green-200 text-green-900 font-bold rounded-full"
                  >
                      <div style="width: 0.4rem; height: 0.4rem" class="bg-gray-500 rounded-full"></div>
                      <div>Activo</div>
                  </div>
                </div>
              </td>            
              <td class="px-4 py-2">
                <div style="min-width: 150px" class="w-full text-sm text-gray-900">
                  {{ data.form.createdAt | moment }}
                </div>
              </td>             
            </tr>
          </tbody>
        </table>
      </div>
      <modal name="newForm"
        :height="300"
        :width="650"
        :adaptive="true">
        <div class="bodyModal">
          <form novalidate @submit.stop.prevent="handleSubmit">
            <h1 class= "text-left text-3xl font-extrabold text-gray-900">Crea tu formulario</h1>
            <span class="mt-2 text-center text-sm text-gray-600">Define el nombre de tu formulario.</span>
            <input 
              v-model.trim="form.name"
              id="name"
              name="name" 
              type="name" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.name.$error }"
              class="mt-5 appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
              placeholder="Nombre Formulario"
            >
            <button type="submit" class="mt-5 group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Crear
            </button>
          </form>
        </div>
      </modal>
    </div>  
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import FormType from "@/store/types/forms/list";
import UserType from "@/store/types/user";
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import Navbar from '@/components/Navbar';

export default {
  name: "forms",
  components: {
    Navbar
  },
  data: function() {
    return {
      headers: ['Name','Owner / Team','Creation Date','Submissions','Status','Notifications'],
      form: {
        name: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      formList: FormType.getters.getForms,
      isPremium: UserType.getters.isPremium,
      newForm: FormType.getters.getNewForm
    })
  },
  validations: {
    form: {
      name: {
        required,
      }
    }
  },
  filters: {
    moment: (date) => {
      return moment(date).format('MMM DD, h:mm:ss A');
    }
  },
  async mounted() {
    this.$wait.start("global");
    try {
      await this.$store.dispatch(FormType.actions.listAll);
    } catch (err) {
      Vue.$toast.error(err.message);
    }
    this.$wait.end("global");
  },
  methods: {
    async handleSubmit() {            
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }
      
      await this.$store.dispatch(FormType.actions.create, {
        name: this.form.name,
      });

      this.form.name = '';

      this.$modal.hide('newForm');

      if (this.newForm.slug) {
        Vue.$toast.success(this.$t('form.list.created'));
        this.$wait.start("global");
        try {
          await this.$store.dispatch(FormType.actions.listAll);
        } catch (err) {
          Vue.$toast.error(err.message);
        }
        this.$wait.end("global");        
      }
    }
  }
};
</script>
<style scoped>
  .bodyModal{
    padding: 50px;
  }
</style>

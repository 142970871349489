<template>
  <div>
    <Navbar :title="form && form.name" :subtitle="slug" /> 
    <div class="w-full flex border-b mb-6 px-6">
      <router-link 
        :to="{ name: 'responses', params: { slug: form.slug, page: 1 }}"
        class="mr-6 p-4" 
        :class="{'border-b-2 border-pink-800': tabSelected === 'responses'}"
      >
        {{$t('form.show.headers.responses')}}
      </router-link>
      <router-link 
        :to="{ name: 'form-share', params: { slug: form.slug }}"
        class="mr-6 p-4" 
        :class="{'border-b-2 border-pink-800': tabSelected === 'share'}"
      >
        {{$t('form.show.headers.share')}}
      </router-link>
      <router-link 
        :to="{ name: 'settings', params: { slug: form.slug }}" 
        class="mr-6 p-4" 
        :class="{'border-b-2 border-pink-800': tabSelected === 'settings'}"
      >
        {{$t('form.show.headers.settings')}}
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import FormType from "@/store/types/forms/show";
import Navbar from '@/components/Navbar';

export default {
  name: "headerForm",
  components: {
    Navbar
  },  
  data: function() {
    return {
      tabSelected: 'responses'
    };
  },
  computed: {
    ...mapGetters({
      form: FormType.getters.form
    }),
    slug() {
      return "ID: " + this.form?.slug;
    }
  },
  async mounted() {
    this.$wait.start("global");
    try {
      this.$data.tabSelected = this.$route.path.split('/')[3];
      await this.$store.dispatch(FormType.actions.loadForm, this.$route.params.slug);
    } catch (err) {
      Vue.$toast.error(err.message);
    }
    this.$wait.end("global");    
  }
}
</script>
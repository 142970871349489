export default class UserService {

  constructor(axios) {
    this.axios = axios;
  }

  /**
   * @param {object} ctx
   * @param {string} ctx.email
   * @param {string} ctx.password
   */
  async login(ctx) {
    try {
      
      const { data: { data: { user, plan, token }} } = await this.axios.post('/api/users/signin', ctx);
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { 
        user, 
        plan,
        token
      };

    } catch (error) {
      this.axios.defaults.headers.common.Authorization = null;

      if (error.response.data) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }
  }

  async logout() {
    this.axios.defaults.headers.common.Authorization = null;
  }

  /**
   * @param {object} ctx
   * @param {string} ctx.name
   * @param {string} ctx.email
   * @param {string} ctx.password
   * @param {string} ctx.terms
   */
  async register(ctx) {
    try {      
      const { data: { data: { user, token }} } = await this.axios.post('/api/users/signup', ctx);      
      this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { 
        user, 
        token 
      };

    } catch (error) {
      this.axios.defaults.headers.common.Authorization = null;
            
      if (error.response.data) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }
  }

  /**
   * @param {object} ctx
   * @param {string} ctx.email
   */
  async requestPasswordRecovery(ctx) {
    try {
      await this.axios.post('/api/users/forgot', ctx);
      return true;
    } catch (error) {
      if (error.response.data) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }
  }

  /**
   * @param {object} ctx
   * @param {string} ctx.token
   * @param {string} ctx.password
   */
  async resetPassword(ctx) {
    try {
      await this.axios.post('/api/users/reset', ctx);
      return true;
    } catch (error) {
      if (error.response.data.error) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }
  }

  async update(dataToUpdate) {
    try {
      const { data } = await this.axios.post(`/api/users/update`, dataToUpdate);
      return { user: data.data.user };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }    
  }

  async updatePassword(dataToUpdate) {
    try {
      const { data } = await this.axios.post(`/api/users/updatePassword`, dataToUpdate);
      return { message: data.data.message };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }
  }

  async updateBillingInformation(dataToUpdate) {
    try {
      const { data } = await this.axios.post(`/api/users/updateBillingInformation`, dataToUpdate);
      return { message: data.data.message };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }
  }

  async activate(token) {
    try {
      const { data } = await this.axios.get(`/api/users/activate/${token}`);
      return { status: data.status };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }
  }
}

import '@/assets/css/tailwind.css';
import router from '@/router';
import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import i18n from '@/localization';
import injector from 'vue-inject';
import VuePageTitle from 'vue-page-title';
import VueLogger from 'vuejs-logger';
import VueWait from 'vue-wait';
import VueElementLoading from "vue-element-loading";
import Vuelidate from 'vuelidate';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import pluralize from 'pluralize';
import VModal from 'vue-js-modal';
import VueClipboard from 'vue-clipboard2';
import VueTheMask from 'vue-the-mask';

require('@/inject');

Vue.component('VueElementLoading', VueElementLoading);

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: false,
  showMethodName: false,
  separator: '|',
  showConsoleColors: true,
});

Vue.use(Vuelidate);
Vue.use(VueWait);
Vue.use(injector);
Vue.use(VueToast);
Vue.use(VModal)
Vue.use(VueClipboard);
Vue.use(VueTheMask);

Vue.use(VuePageTitle, {
  suffix: `| ${process.env.VUE_APP_TITLE}`,
  router,
});
Vue.filter('pluralize', function (value, number, extra, empty) {
  if (!number) {
    return empty;
  }
  return `${pluralize(value, number, true)} ${extra}`;
});

Vue.config.productionTip = false;

new Vue({  
  router,
  store,
  wait: new VueWait(),
  i18n,
  render: h => h(App),
}).$mount('#app')


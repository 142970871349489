<template>
  <div>
    <HeaderForm/>
    <div class="flex flex-col max-w-screen-md px-6">
        <div class="font-bold leading-3	mb-2">{{$t('form.show.share.title')}}</div>
        <div class="text-sm">{{$t('form.show.share.subtitle')}}</div>
        <div class="flex items-center justify-between py-5">
          <input 
            v-model.trim="formURL"
            id="name"
            name="name" 
            type="name"
            readonly="true"
            autocomplete="off"
            class="appearance-none rounded relative block w-full px-3 py-3 border border-indigo-500 text-gray-900 sm:text-sm" 
          >
          <button 
            type="button" 
            class="ml-3 flex" 
            v-clipboard:copy="formURL"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <ClipboardCopy class="mr-1"/>
            {{$t('form.show.share.copy')}}            
          </button>
        </div>
        <div class="flex items-center">
          <a class="underline" href="https://docs.simpleapiform.com/es/quick-start/" target="_blank">
            {{$t('form.show.share.instructions')}}
          </a>
          <ExternLinkIcon class="ml-1"/>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import FormType from "@/store/types/forms/show";
import HeaderForm from '@/pages/forms/show/header';
import ExternLinkIcon from '@/components/icons/ExternalLink';
import ClipboardCopy from '@/components/icons/ClipboardCopy';

export default {
  name: "share",
  components: {
    HeaderForm,
    ExternLinkIcon,
    ClipboardCopy
  },
  data: function() {
    return {
      notifications: false,
    }
  },  
  computed: {
    ...mapGetters({
      headers: FormType.getters.headers,
      form: FormType.getters.form
    }),
    formURL() {
      return `https://simpleapiform.com/f/${this.form.slug}`
    }
  },
  methods: {
    onCopy: function () {
      Vue.$toast.success(this.$t('form.show.share.copiedMessage'));      
    },
    onError: function () {
      Vue.$toast.success(this.$t('form.show.share.failToCopiedMessage'));
    }
  }
};
</script>
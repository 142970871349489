export default class BillingService {

  constructor(axios) {
    this.axios = axios;
  }

  async addCard(cardData) {
    try {
      const { data } = await this.axios.post(`/api/billing/cards/add`, cardData);
      return { card: data.data.card };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }
  }
  
  async listCards() {
    try {      
      const { data } = await this.axios.get(`/api/billing/cards/list`);
      return { cards: data.data.cards };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }    
  }

  async updateCard(cardData) {
    try {
      const { data } = await this.axios.post(`/api/billing/cards/update`, cardData);
      return { card: data.data.card };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }
  }
}
export default class CulqiService {

  constructor(axios) {
    this.axios = axios;
  }


  /**
   * Creates a Token using Culqi API
   * 
   * requestData = {
   *   email,
   *   card_number,
   *   cvv,
   *   expiration_year,
   *   expiration_month
   * }
   */
  async createToken(requestData, publicKey) {
    try {
      
      const { data } = await this.axios.post(`https://secure.culqi.com/v2/tokens`, requestData, {
        headers: {
          'Authorization': `Bearer ${publicKey}`,
          'X-API-VERSION': 2,
          'X-API-KEY': publicKey,
          'X-CULQI-ENV': 'live'
        }
      });

      return { 
        token: data.id,
        lastFour: data.last_four,
        cardBrand: data.iin.card_brand,
        cardNumber: data.card_number
      };

    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }    
  }

}
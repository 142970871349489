import injector from 'vue-inject';
import UserService from './user.service';
import FormService from './form.service';
import SubmissionService from './submission.service';
import CulqiService from './culqi.service';
import BillingService from './billing.service';

injector.service('UserService', ['axios'], UserService);
injector.service('FormService', ['axios'], FormService);
injector.service('SubmissionService',['axios'], SubmissionService);
injector.service('CulqiService', ['axios'], CulqiService);
injector.service('BillingService', ['axios'], BillingService);

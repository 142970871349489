import namespace from '../../namespace';

export default namespace('submission', {
  getters: [
    'responses',
    'headers',
    'form',
    'name',
    'notifications',
    'onCompleteRedirectTo',
    'pagination',
    'active',
    'recaptcha'
  ],
  mutations: [
    'setResponses',
    'clearResponses',
    'setForm',
    'setName',
    'setNotifications',
    'setOnCompleteRedirectTo',
    'setActive',
    'setRecaptcha'
  ],
  actions: [
    'loadResponses',
    'loadForm',
    'updateForm',
    'getGoogleAuthURL',
    'downloadCsv'
  ],
});
import namespace from '../namespace';

export default namespace('user', {
  getters: [
    'cards'
  ],
  mutations: [
    'setCards',
    'updateCard'
  ],
  actions: [
    'addCard',
    'listCards',
    'updateCard'
  ],
});

export default class FormService {

  constructor(axios) {
    this.axios = axios;
  }

  async findOne(slug) {
    try {
      const { data } = await this.axios.get(`/api/forms/findOne/${slug}`);
      return { form: data.data.form };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }    
  }

  async findAll() {
    try {
      const { data: { data:forms } } = await this.axios.get('/api/forms/findAll');
      return { forms };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }    
  }

  async create(request) {
    try {
      const { data } = await this.axios.post('/api/forms/create', request);
      return { form: data.data.form };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }
  }

  async update(slug, dataToUpdate) {
    try {
      const { data } = await this.axios.post(`/api/forms/update/${slug}`, dataToUpdate);
      return { form: data.data.form };
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }
  }

  async getGoogleOAuthURL(slug) {
    try {
      const { data: { data } } = await this.axios.post('/api/integrations/spreadsheet/oauth', {slug: slug});
      return data;
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        throw new Error(message);
      }
      throw error;
    }    
  }  
}
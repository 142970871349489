export default class SubmissionService {

  constructor(axios) {
    this.axios = axios;
  }

  async findAll(slug, page) {
    try {
      const { data: { data } } = await this.axios.get(`/api/forms/${slug}/submissions?page=${page}`);
      return data;
    } catch (error) {
      if (error.response.data.error) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }    
  }

  async downloadCsv(slug) {
    try {
      return await this.axios.post(`/api/forms/${slug}/submissions/download-csv`);
    } catch (error) {
      if (error.response.data.error) {
        const { message } = error.response.data.error;
        throw new Error(message);
      }
      throw error;
    }    
  }
}
<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-xs w-full space-y-8" v-if="!send">
      <div>
        <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {{$t('reset.title')}}
        </h2>
      </div>
      <form 
        novalidate 
        class="mt-8 space-y-6"
        @submit.stop.prevent="handleSubmit"
      >
        <div class="rounded-md">         
          <div>
            <label for="password" class="sr-only">{{$t('reset.form.password')}}</label>
            <input 
              v-model.trim="form.password" 
              :state="validateState('password')"
              id="password" 
              name="password" 
              type="password" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.password.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('reset.form.password')">
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.password.$error">
              <div v-if="!$v.form.password.required">{{$t('reset.form.passwordRequired')}}</div>                            
              <div v-if="!$v.form.password.minLength">{{$t('reset.form.passwordMinLength')}}</div>
            </div>              
          </div>
        </div>
        <div class="rounded-md">         
          <div>
            <label for="confirmPassword" class="sr-only">{{$t('reset.form.confirmPassword')}}</label>
            <input 
              v-model.trim="form.confirmPassword" 
              :state="validateState('confirmPassword')"
              id="confirmPassword" 
              name="confirmPassword" 
              type="password" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.confirmPassword.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('reset.form.confirmPassword')">
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.confirmPassword.$error">
              <div v-if="!$v.form.confirmPassword.required">{{$t('reset.form.confirmPasswordRequired')}}</div>                            
              <div v-if="!$v.form.confirmPassword.minLength">{{$t('reset.form.confirmPasswordMinLength')}}</div>
              <div v-if="!$v.form.confirmPassword.sameAsPassword">{{$t('reset.form.confirmPasswordSame')}}</div>             
            </div>              
          </div>
        </div>
        <div>
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            {{$t('reset.form.submit')}}
          </button>
        </div>
      </form>
    </div>
    <div class="max-w-xs w-full space-y-8" v-if="send">
      <div>
        <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {{$t('reset.successTitle')}}
        </h2>
        <br>
        <a href="/login" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          {{$t('reset.continueToLogin')}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import UserType from '@/store/types/user';

export default {
  name: 'Reset',
  data() {
    return {    
      send: false,  
      form: {
        password: '',
        confirmPassword: ''
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      }      
    }
  },
  methods: {
    validateState(field) {
      const { $dirty, $error } = this.$v.form[field];
      return $dirty ? !$error : null;
    },    
    async handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const token = this.$route.query.token;

      if (!token) {
        return Vue.$toast.error("Token invalid");
      }
      
      try {
        await this.$store.dispatch(UserType.actions.reset, { 
          password: this.$data.form.password, 
          token
        });
        this.$data.send = true;
      } catch (err) {
        Vue.$toast.error(err.message);
        this.$data.send = false;
      }
    }
  },
};
</script>

<template>
  <div class="flex flex-col flex-grow">
    <HeaderForm/>
    <div class="flex flex-col max-w-screen-md divide-y divide-light px-6">
      <div class="flex items-center justify-between py-5">
        <div>
          <div class="font-bold leading-3	mb-2">{{ $t('form.show.settings.name.title') }}</div>
          <div class="text-sm">{{ $t('form.show.settings.name.subtitle') }}</div>
        </div>
      </div>
      <div>
        <div class="relative mb-3">
          <input 
            id="name"
            name="name"
            v-model="name"
            type="text"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('form.show.settings.name.placeholder')"
          />
        </div>
      </div>    
      <div class="flex items-center justify-between py-5">
        <div>
          <div class="font-bold leading-3	mb-2">{{ $t('form.show.settings.email.title') }}</div>
          <div class="text-sm">{{ $t('form.show.settings.email.subtitle') }}</div>
        </div>
        <div>
          <div 
            v-on:click="onChangeSendMail" 
            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
          >
              <input
                v-model="notifications"
                type="checkbox"
                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              />
              <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
          </div>        
        </div>
      </div>
      <div class="flex items-center justify-between py-5">
        <div>
          <div class="font-bold leading-3	mb-2">{{ $t('form.show.settings.close.title') }}</div>
          <div class="text-sm">{{ $t('form.show.settings.close.subtitle') }}</div>
        </div>
        <div>
          <div 
            v-on:click="onChangeClose" 
            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
          >
              <input
                v-model="close"
                type="checkbox"
                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              />
              <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
          </div>        
        </div>        
      </div>       
      <div class="flex items-center justify-between py-5">
        <div>
          <div class="font-bold leading-3	mb-2">{{ $t('form.show.settings.redirect.title') }}</div>
          <div class="text-sm">{{ $t('form.show.settings.redirect.subtitle') }}</div>
        </div>
      </div>
      <div>
        <div class="relative mb-3">
          <input 
            id="onCompleteRedirectTo"
            name="onCompleteRedirectTo"
            v-model="onCompleteRedirectTo"
            type="text"                         
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('form.show.settings.redirect.placeholder')" 
          />
          <div class="text-xs mt-2 text-red-400" v-if="!$v.onCompleteRedirectTo.url">
            {{ $t('form.show.settings.redirect.urlRequired') }}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between py-5">
        <div>
          <div class="font-bold leading-3	mb-2">{{ $t('form.show.settings.recaptcha.title') }}</div>
          <div class="text-sm">{{ $t('form.show.settings.recaptcha.subtitle') }}</div>
        </div>
      </div>
      <div>
        <div class="relative mb-3">
          <input 
            id="recaptcha"
            name="recaptcha"
            v-model="recaptcha"
            type="text"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('form.show.settings.recaptcha.placeholder')"
          />
        </div>
      </div>                
      <div class="flex items-center justify-between py-5">
        <button
          v-on:click="onSaveChanges"
          class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {{ $t('form.show.settings.save') }}
        </button>
      </div>      
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { url } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import ShowFormType from "@/store/types/forms/show";
import HeaderForm from '@/pages/forms/show/header';

export default {
  name: "settings",
  components: {
    HeaderForm
  },
  data: function() {
    return {}
  },
  validations: {
    onCompleteRedirectTo: {
      url
    }
  },
  computed: {
    ...mapGetters({
      headers: ShowFormType.getters.headers,
      form: ShowFormType.getters.form
    }),
    name: {
      get: function() {
        return this.$store.getters[ShowFormType.getters.name];
      },
      set: function(val) {
        this.$store.commit(ShowFormType.mutations.setName, val);
      }
    },    
    notifications: {
      get: function() {
        return this.$store.getters[ShowFormType.getters.notifications];
      },
      set: function(val) {
        this.$store.commit(ShowFormType.mutations.setNotifications, val);
      }
    },
    onCompleteRedirectTo: {
      get: function() {
        return this.$store.getters[ShowFormType.getters.onCompleteRedirectTo];
      },
      set: function(val) {
        this.$v.onCompleteRedirectTo.$touch();
        this.$store.commit(ShowFormType.mutations.setOnCompleteRedirectTo, val);
      }
    },
    close: {
      get: function() {
        return this.$store.getters[ShowFormType.getters.active];
      },
      set: function(val) {
        this.$store.commit(ShowFormType.mutations.setActive, val);
      }
    },
    recaptcha: {
      get: function() {
        return this.$store.getters[ShowFormType.getters.recaptcha];
      },
      set: function(val) {
        this.$store.commit(ShowFormType.mutations.setRecaptcha, val);
      }
    }
  },
  methods: {
    onChangeSendMail: function() {
      this.notifications = !this.notifications;
    },
    onChangeClose: function() {
      this.close = !this.close;
    },
    onSaveChanges: async function() {
      this.$wait.start("global");
      try {
        await this.$store.dispatch(ShowFormType.actions.updateForm);
        Vue.$toast.success(this.$t('form.show.settings.saveMessage'));
      } catch (err) {
        Vue.$toast.error(err.message);
      }
      this.$wait.end("global");
    }
  }
};
</script>
<style>
/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
}
</style>
//import jwtDecode from 'jwt-decode';
import store from 'store2';
import injector from 'vue-inject';
import UserType from '../types/user';

const state = {
  user: null,
  plan: null,
  token: null,
  paymentMethods: [],
  editableUser: {
    name: '',
    email: ''
  },
  password: {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }
};

const getters = {
  [UserType.getters.getUser](state) {
    return state.user;
  },
  [UserType.getters.getPlan](state) {
    return state.plan;
  },
  [UserType.getters.isPremium](state) {
    return state.plan.name !== 'free';
  },
  [UserType.getters.getToken](state) {
    return state.token;
  },
  [UserType.getters.name](state) {
    return state.editableUser.name;
  },
  [UserType.getters.email](state) {
    return state.editableUser.email;
  },
  [UserType.getters.currentPassword](state) {
    return state.password.currentPassword;
  },
  [UserType.getters.newPassword](state) {
    return state.password.newPassword;
  },
  [UserType.getters.confirmPassword](state) {
    return state.password.confirmPassword;
  },
  [UserType.getters.sendToAlternativeBillingEmail](state) {
    return state.user.sendToAlternativeBillingEmail;
  },
  [UserType.getters.alternativeBillingEmail](state) {
    return state.user.alternativeBillingEmail;
  },
  [UserType.getters.paymentMethods](state) {
    return state.paymentMethods;
  }
};

const mutations = {
  [UserType.mutations.setUser](state, user) {
    state.user = user;
  },
  [UserType.mutations.setPlan](state, plan) {
    state.plan = plan;
  },
  [UserType.mutations.setToken](state, token) {
    state.token = token;
  },
  [UserType.mutations.setName](state, name) {
    state.editableUser.name = name;
  },
  [UserType.mutations.setEmail](state, email) {
    state.editableUser.email = email;
  }, 
  [UserType.mutations.setCurrentPassword](state, currentPassword) {
    state.password.currentPassword = currentPassword;
  },
  [UserType.mutations.setNewPassword](state, newPassword) {
    state.password.newPassword = newPassword;
  },
  [UserType.mutations.setConfirmPassword](state, confirmPassword) {
    state.password.confirmPassword = confirmPassword;
  },
  [UserType.mutations.setSendToAlternativeBillingEmail](state, sendToAlternativeBillingEmail) {
    state.user.sendToAlternativeBillingEmail = sendToAlternativeBillingEmail;
  },
  [UserType.mutations.setAlternativeBillingEmail](state, alternativeBillingEmail) {
    state.user.alternativeBillingEmail = alternativeBillingEmail;
  },
  [UserType.mutations.setPaymentMethods](state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  }  
};

const actions = {
  [UserType.actions.setupAxios]: injector.encase(['axios'], (axios) => async () => {    
    if (store.session.has('TID')) {
      const token = store.session.get('TID');
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }),
  [UserType.actions.register]: injector.encase(['UserService'], (UserService) => async ({ commit }, credentials) => {

    const { user, plan, token } = await UserService.register(credentials);

    store.session.set('TID', token);
    
    commit(UserType.mutations.setUser, user);
    commit(UserType.mutations.setPlan, plan);
    commit(UserType.mutations.setToken, token);

    commit(UserType.mutations.setName, user.name);
    commit(UserType.mutations.setEmail, user.email);    
    
    return true;
  }),  
  [UserType.actions.login]: injector.encase(['UserService'], (UserService) => async ({ commit }, credentials) => {
    
    const { user, plan, token } = await UserService.login(credentials);

    store.session.set('TID', token);
    
    commit(UserType.mutations.setUser, user);
    commit(UserType.mutations.setPlan, plan);
    commit(UserType.mutations.setToken, token);

    commit(UserType.mutations.setName, user.name);
    commit(UserType.mutations.setEmail, user.email);
    
    return true;
  }),
  [UserType.actions.logout]: injector.encase(['UserService'], (UserService) => async () => {

    await UserService.logout();

    store.session.remove('TID');
  }),
  [UserType.actions.resetData]: injector.encase(['UserService'], () => async ({ commit }) => {
    commit(UserType.mutations.setUser, null);
    commit(UserType.mutations.setPlan, null);
    commit(UserType.mutations.setToken, null);

    commit(UserType.mutations.setName, null);
    commit(UserType.mutations.setEmail, null);
  }),
  [UserType.actions.forgot]: injector.encase(['UserService'],  (UserService) => async (_, form) => {
    await UserService.requestPasswordRecovery(form);
  }),
  [UserType.actions.reset]: injector.encase(['UserService'], (UserService) => async (_, ctx) => {
    await UserService.resetPassword(ctx);    
  }),
  [UserType.actions.updateUser]: injector.encase(['UserService'], (UserService) => async ({ commit, state }) => {
    if (state.editableUser) {

      const { user } = await UserService.update({
        name: state.editableUser.name,
        email: state.editableUser.email
      });
      
      commit(UserType.mutations.setUser, user);
      commit(UserType.mutations.setName, user.name);
      commit(UserType.mutations.setEmail, user.email);
    }
  }),
  [UserType.actions.updatePassword]: injector.encase(['UserService'], (UserService) => async ({ state }) => {
    
    const { message } = await UserService.updatePassword({
      currentPassword: state.password.currentPassword,
      newPassword: state.password.newPassword
    });
    
    return message;
  }),
  [UserType.actions.updateBillingInformation]: injector.encase(['UserService'], (UserService) => async ({ state }) => {
    
    const { message } = await UserService.updateBillingInformation({
      sendToAlternativeBillingEmail: state.user.sendToAlternativeBillingEmail,
      alternativeBillingEmail: state.user.alternativeBillingEmail
    });

    return message;
  }),
  [UserType.actions.addPaymentMethod]: injector.encase(['CulqiService', 'UserService'], (CulqiService, UserService) => async (_, cardData) => {
    
    const [expiration_month, expiration_year] = cardData.expireDate.split('/'); 

    const tokenData = await CulqiService.createToken({
      email: cardData.email,
      card_number: cardData.cardNumber,
      cvv: cardData.cvv,
      expiration_year: expiration_year.trim(),
      expiration_month: expiration_month.trim()
    }, 'pk_test_LsRBKejzCOEEWOwO');

    const { card } = await UserService.addPaymentMethod({
      ...tokenData,
      expireMonth: expiration_month.trim(),
      expireYear: expiration_year.trim()
    });

    return card;
  }),
  [UserType.actions.listPaymentMethods]: injector.encase(['UserService'], (UserService) => async ({ commit }) => {
    
    const { paymentMethods } = await UserService.listPaymentMethods();
    commit(UserType.mutations.setPaymentMethods, paymentMethods);
    return paymentMethods;
  }),
  [UserType.actions.activate]: injector.encase(['UserService'], (UserService) => async (_, token) => {
    await UserService.activate(token);
  }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import namespace from '../../namespace';

export default namespace('form', {
  getters: [
    'getForms',
    'getNewForm'
  ],
  mutations: [
    'setForms',
    'setForm'
  ],
  actions: [
    'listAll',
    'create'
  ],
});
<template>
  <div>
    <HeaderForm/>
    <div class="flex flex-col max-w-screen-md px-6">
      <div class="flex items-center justify-between py-5">
        <div>
          <div class="font-bold leading-3	mb-2">{{ $t('form.show.integrations.sheets.title') }}</div>
          <div class="text-sm">{{ $t('form.show.integrations.sheets.subtitle') }}</div>
        </div>
        <div>
          <button
            v-if="!form.integrations.spreadsheet.active" 
            v-on:click="getGooleAuthPopup"
            class="h-10 px-5 text-indigo-700 transition-colors duration-150 border border-indigo-500 rounded-lg focus:shadow-outline hover:bg-indigo-500 hover:text-indigo-100"
          >
            {{$t('form.show.integrations.sheets.connect')}}
          </button>
          <button
            v-if="form.integrations.spreadsheet.active" 
          >
            <TrashIcon/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import FormType from "@/store/types/forms/show";
import HeaderForm from '@/pages/forms/show/header';
import TrashIcon from '@/components/icons/Trash';

export default {
  name: "integrations",
  components: {
    HeaderForm,
    TrashIcon
  },
  data: function() {
    return {
    }
  },  
  computed: {
    ...mapGetters({
      headers: FormType.getters.headers,
      form: FormType.getters.form
    })
  },
  methods: {
    async getGooleAuthPopup() {
    this.$wait.start("global");
    try {
      const { authUrl } = await this.$store.dispatch(FormType.actions.getGoogleAuthURL);

      const popupCenter = ({url, title, w, h}) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, 
          `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )

        if (window.focus) newWindow.focus();
      }

      popupCenter({url: authUrl, title: 'googleAuth', w: 500, h: 500});
    
    } catch (err) {
      Vue.$toast.error(err.message);
    }
    this.$wait.end("global");
    }
  }
};
</script>
<template>
  <div class="flex flex-col flex-grow">
    <HeaderForm/>
    <div class="mb-4 flex flex-wrap w-full px-6">
      <div class="flex flex-wrap w-full" v-if="responses && responses.length > 0">
        <div class="flex flex-col w-full">
          <div class="flex flex-row w-full justify-between">
            <div class="mb-2 ml-2 font-bold">
              {{ $tc('form.show.responses.count', totalNumberOfResponses, { count: totalNumberOfResponses }) }}
            </div>
            <div class="mr-2">
              <vue-element-loading 
                :active='$wait.waiting("download")'
                spinner="bar-fade-scale" />
              <button class="flex items-center" @click="downloadCSV">
                {{$t('form.show.responses.export')}}
                <Download class="ml-1"/>
              </button>
            </div>
          </div>
          <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle min-w-full sm:px-6 lg:px-8">
              <div class="shadow border border-gray-300 sm:rounded-lg overflow-y-auto max-h-table">
                <table class="w-full divide-y divide-gray-200 table-auto">
                  <thead>
                    <tr>
                      <th scope="col" class="w-48 whitespace-nowrap px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 bg-gray-50 border-b border-gray-300">
                          {{$t('form.show.responses.submittedAt')}}                
                      </th>
                      <th 
                        v-for="header in headers" 
                        v-bind:key="header" 
                        scope="col" 
                        class="whitespace-nowrap px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 bg-gray-50 border-b border-gray-300"
                      >
                          {{header}}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="sub in responses" v-bind:key="sub.slug">
                      <td class="px-4 py-2">
                        <div style="min-width: 150px" class="w-full text-sm text-gray-900">
                          {{ sub.createdAt | moment }}
                        </div>
                      </td>
                      <td class="px-4 py-2" v-for="header in headers" v-bind:key="header">
                        <div style="min-width: 150px" class="w-full text-sm text-gray-900">
                          {{sub.body[header] || '-'}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="flex mt-2">
            <span class="mr-2 mt-1 text-gray-500">{{$t('form.show.responses.page')}}</span>
            <button
              v-on:click.prevent="previousPage"
              :disabled="$route.params.page == 1"
              class="text-gray-600"
              :class="{'text-gray-100 opacity-50': $route.params.page == 1}"
            >
              <ArrowLeft/>
            </button>
            <select @change="onPageChange"
              class="bg-white border border-gray-300 px-1 rounded-md text-center text-gray-500 w-16"
            >
              <option
                v-for="page in pagination.numberOfPages"
                :key="page"
                :value="page"
                :selected="page == $route.params.page"
              >
                {{page}}
              </option>
            </select>
            <button
              v-on:click.prevent="nextPage"
              :disabled="$route.params.page == pagination.numberOfPages"
              class="text-gray-600"
              :class="{'text-gray-100 opacity-50': $route.params.page == pagination.numberOfPages}"
            >
              <ArrowRight/>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap" v-if="responses && responses.length === 0">
        <p class="mt-2 text-center text-sm text-gray-600">
          {{$t('form.show.responses.empty')}}
        </p>
      </div>
    </div>    
  </div>


</template>

<script>
import Vue from "vue";
import { mapGetters } from 'vuex';
import FormType from "@/store/types/forms/show";
import moment from 'moment';
import HeaderForm from '@/pages/forms/show/header';
import ArrowLeft from '@/components/icons/ArrowLeft';
import ArrowRight from '@/components/icons/ArrowRight';
import Download from '@/components/icons/Download';

export default {
  name: "responses",
  components: {
    HeaderForm,
    ArrowLeft,
    ArrowRight,
    Download
  },
  data: function() {
    return {}
  },  
  computed: {
    ...mapGetters({
      headers: FormType.getters.headers,
      responses: FormType.getters.responses,
      form: FormType.getters.form,
      totalNumberOfResponses: FormType.getters.totalNumberOfResponses,
      pagination: FormType.getters.pagination
    }),
  },
  filters: {
    moment: (date) => {
      return moment(date).format('MMM DD, h:mm:ss A');
    }
  },
  methods: {
    onPageChange(event) {
      const page = event.target.value;
      if (page !== parseInt(this.$route.params.page)) {
        this.$router.push({ name: 'responses', params: { slug: this.$route.params.slug, page } });
      }
    },
    previousPage() {
      const page = parseInt(this.$route.params.page) - 1;
      this.$router.push({ name: 'responses', params: { slug: this.$route.params.slug, page  }});
    },
    nextPage() {
      const page = parseInt(this.$route.params.page) + 1;
      this.$router.push({ name: 'responses', params: { slug: this.$route.params.slug, page  }});
    },
    async downloadCSV() {
      this.$wait.start("download");
      const response = await this.$store.dispatch(FormType.actions.downloadCsv, { slug: this.$route.params.slug });
      if (response.data) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${this.form.name}.csv`
        link.click()
      }
      this.$wait.end("download");
    }
  },
  async mounted() {
    this.$wait.start("global");
    try {
      const page = this.$route.params.page? this.$route.params.page : 1;
      await this.$store.dispatch(FormType.actions.loadResponses, { slug: this.$route.params.slug, page: page });
    } catch (err) {
      Vue.$toast.error(err.message);
    }
    this.$wait.end("global");
  }
};
</script>
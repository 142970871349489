import store from '@/store';
import UserType from '@/store/types/user';
import jwtDecode from 'jwt-decode';
import Vue from 'vue';
import Router from 'vue-router';

import Dashboard from '@/layouts/Dashboard';
import FormList from '@/pages/forms/List';
import Responses from '@/pages/forms/show/responses';
import FormSettings from '@/pages/forms/show/settings';
import FormShare from '@/pages/forms/show/share';
import FormIntegrations from '@/pages/forms/show/integrations';
import FileList from '@/pages/files/List';
import AddPeople from '@/pages/teams/Add';
import TeamList from '@/pages/teams/List';
import Profile from '@/pages/users/settings/Profile';
import Password from '@/pages/users/settings/Password';
import BillingPlan from '@/pages/accounts/BillingPlan';
import BillingInformation from '@/pages/accounts/BillingInformation';
import BillingHistory from '@/pages/accounts/BillingHistory';
import Login from '@/pages/users/Login';
import Register from '@/pages/users/Register';
import Forgot from '@/pages/users/Forgot';
import Reset from '@/pages/users/Reset';
import Activate from '@/pages/users/Activate';

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: Dashboard, 
    redirect: { name: 'FormList' },
    meta: { authRequired: true },
    children: [{
      path: '/forms',
      name: 'FormList',
      component: FormList,
      meta: {
        title: 'Mis Formularios'
      }
    },{
      path: 'forms/:slug/responses/:page?',
      name: 'responses',
      component: Responses,
      meta: {
        title: 'Mis Formularios'
      }      
    },{
      path: 'forms/:slug/settings',
      name: 'settings',
      component: FormSettings
    },{
      path: 'forms/:slug/share',
      name: 'form-share',
      component: FormShare
    },{
      path: 'forms/:slug/integrations',
      name: 'integrations',
      component: FormIntegrations
    
    },{
      path: '/files',
      name: 'FileList',
      component: FileList
    },{
      path: '/team/add',
      name: 'AddPeople',
      component: AddPeople
    },{
      path: '/team',
      name: 'TeamList',
      component: TeamList      
    },{
      path: '/users/settings/profile',
      name: 'profile',
      component: Profile
    },{
      path: '/users/settings/password',
      name: 'password',
      component: Password
    },{
      path: '/account/billing/plan',
      name: 'billingPlan',
      component: BillingPlan
    },{
      path: '/account/billing/information',
      name: 'billingInformation',
      component: BillingInformation
    }, {
      path: '/account/billing/history',
      name: 'billingHistory',
      component: BillingHistory
    }]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'login' },
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: 'register' },
    component: Register
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: { title: 'forgot' },
    component: Forgot
  },
  {
    path: '/reset',
    name: 'reset',
    meta: { title: 'reset' },
    component: Reset
  },
  {
    path: '/activate/:token?',
    name: 'activate',
    meta: { title: 'activate' },
    component: Activate
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let isTokenExpired = false;
  let isUserStateInvalid = false;

  const { user, token } = store.state.user;

  if (user && token) {

    const { exp } = jwtDecode(token);

    if (exp) {
      isTokenExpired = Date.now() >= exp * 1000;
    }

    if (user.status !== 'active') {
      isUserStateInvalid = true;
    }

    if (!isTokenExpired) {
      store.dispatch(UserType.actions.setupAxios).then(next).catch(console.error);
    }
  }

  if (to.matched.some((record) => record.meta.authRequired)
    && (!user || isTokenExpired || isUserStateInvalid)) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
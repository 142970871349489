<template>
  <div class="flex flex-col flex-grow">
  </div>
</template>

<script>

export default {
  name: "team",
  components: {    
  },
  computed: {

  },
  filters: {
  
  },
  async mounted() {

  },
};
</script>
import axios from 'axios';
import mitt from 'mitt';
import injector from 'vue-inject';

const isProduction = process.env.NODE_ENV === 'production';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

injector.constant('isProduction', isProduction);
injector.constant('axios', axiosInstance);
injector.constant('eventBus', mitt());
import ShowFormType from '../../types/forms/show';
import injector from 'vue-inject';

const state = {
  form: null,
  editableForm: {
    name: '',
    notifications: false,
    onCompleteRedirectTo: '',
    active: false,
    recaptcha: ''
  }, 
  responses: null,
  totalNumberOfResponses: null,
  pagination: {
    numberOfPages: null,
    currentPage:null
  }
}

const getters = {
  [ShowFormType.getters.responses](state) {
    return state.responses;
  },
  [ShowFormType.getters.headers](state) {
    return state.headers;
  },
  [ShowFormType.getters.form](state) {
    return state.form;
  },
  [ShowFormType.getters.pagination](state) {
    return state.pagination;
  },
  [ShowFormType.getters.totalNumberOfResponses](state) {
    return state.totalNumberOfResponses;
  },
  [ShowFormType.getters.name](state) {
    return state.editableForm.name;
  },  
  [ShowFormType.getters.notifications](state) {
    return state.editableForm.notifications;
  },
  [ShowFormType.getters.onCompleteRedirectTo](state) {
    return state.editableForm.onCompleteRedirectTo;
  },
  [ShowFormType.getters.active](state) {
    return state.editableForm.active;
  },
  [ShowFormType.getters.recaptcha](state) {
    return state.editableForm.recaptcha;
  }  
}

const mutations = {
  [ShowFormType.mutations.setResponses](state, { submissions, headers, totalNumberOfSubmissions, page, limit }) {
    state.responses = submissions;
    state.headers = headers;
    state.totalNumberOfResponses = totalNumberOfSubmissions;
    state.pagination = {
      numberOfPages: Math.ceil(totalNumberOfSubmissions / limit),
      currentPage: page
    }
  },
  [ShowFormType.mutations.clearResponses](state) {
    state.responses = null;
    state.headers = null;
    state.totalNumberOfResponses = null;
    state.pagination = {
      numberOfPages: null,
      currentPage: null
    }
  },
  [ShowFormType.mutations.setForm](state, form) {
    state.form = form;
  },
  [ShowFormType.mutations.setName](state, name) {
    state.editableForm.name = name;
  },  
  [ShowFormType.mutations.setNotifications](state, notifications) {
    state.editableForm.notifications = notifications;
  },
  [ShowFormType.mutations.setOnCompleteRedirectTo](state, onCompleteRedirectTo) {
    state.editableForm.onCompleteRedirectTo = onCompleteRedirectTo;
  },
  [ShowFormType.mutations.setActive](state, active) {
    state.editableForm.active = active;
  },
  [ShowFormType.mutations.setRecaptcha](state, recaptcha) {
    state.editableForm.recaptcha = recaptcha;
  }  
}

const actions = {
  [ShowFormType.actions.loadResponses]: injector.encase(['SubmissionService'], (SubmissionService) => async ({ commit }, { slug, page }) => {
    try {
      if (slug) {
        const data = await SubmissionService.findAll(slug, page);
        commit(ShowFormType.mutations.setResponses, data);
      }
    } catch (error) {
      commit(ShowFormType.mutations.clearResponses);
      throw error;
    }
  }),
  [ShowFormType.actions.loadForm]: injector.encase(['FormService'], (FormService) => async ({ commit }, slug) => {
    try {
      if (slug) {
        const { form } = await FormService.findOne(slug);

        commit(ShowFormType.mutations.setForm, form);
        commit(ShowFormType.mutations.setName, form.name);
        commit(ShowFormType.mutations.setNotifications, form.notifications);
        commit(ShowFormType.mutations.setOnCompleteRedirectTo, form.onCompleteRedirectTo);
        commit(ShowFormType.mutations.setActive, !form.active);
        commit(ShowFormType.mutations.setRecaptcha, form.recaptchaSecretKey);
      }
    } catch (error) {
      commit(ShowFormType.mutations.setForm, null);
      throw error;
    }
  }),
  [ShowFormType.actions.updateForm]: injector.encase(['FormService'], (FormService) => async ({ commit, state }) => {
    try {
      if (state.form) {
        

        const { form } = await FormService.update(state.form.slug, {
          name: state.editableForm.name,
          notifications: state.editableForm.notifications,
          onCompleteRedirectTo: state.editableForm.onCompleteRedirectTo,
          active: !state.editableForm.active,
          recaptchaSecretKey: state.editableForm.recaptcha
        });
        
        commit(ShowFormType.mutations.setForm, form);
        commit(ShowFormType.mutations.setName, form.name);
        commit(ShowFormType.mutations.setNotifications, form.notifications);
        commit(ShowFormType.mutations.setOnCompleteRedirectTo, form.onCompleteRedirectTo);
        commit(ShowFormType.mutations.setActive, !form.active);
        commit(ShowFormType.mutations.setRecaptcha, form.recaptchaSecretKey);
      }
    } catch (error) {
      commit(ShowFormType.mutations.setForm, null);
      throw error;
    }
  }),
  [ShowFormType.actions.getGoogleAuthURL]: injector.encase(['FormService'], (FormService) => async ({state}) => {
    return FormService.getGoogleOAuthURL(state.form.slug);
  }),
  [ShowFormType.actions.downloadCsv]: injector.encase(['SubmissionService'], (SubmissionService) => async ({ commit }, { slug }) => {
    try {
      if (slug) {
        return await SubmissionService.downloadCsv(slug);
      }
    } catch (error) {
      commit(ShowFormType.mutations.clearResponses);
      throw error;
    }
  }),
}

export default {
  state,
  getters,
  mutations,
  actions,
};
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import spanish from '@/locales/es-PE';
import store from '@/store';

const locales = {
  'es-PE': spanish,
};

Vue.use(VueI18n);

const localeByDefault = 'es-PE';

export default new VueI18n({
  locale: store.state.global.language ?? localeByDefault,
  fallbackLocale: localeByDefault,
  messages: {
    'es-PE': locales[localeByDefault],
  },
});

<template>
  <div>
    <Navbar :title="$t('account.profile.title')"/>
    <div class="flex border-b mb-6 px-6">
      <router-link 
        :to="{ name: 'profile'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'profile'}"
      >
        {{$t('account.profile.headers.general')}}
      </router-link>
      <router-link 
        :to="{ name: 'password'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'password'}"
      >
        {{$t('account.profile.headers.password')}}
      </router-link>      
    </div>
    <div class="flex flex-col max-w-screen-md px-6 mb-6">
      <!-- Name -->
      <div class="mb-3">
        <div class="font-bold leading-3	mb-2">{{ $t('account.profile.general.name.title') }}</div>
        <div class="text-sm mb-2">{{ $t('account.profile.general.name.subtitle') }}</div>
        <div class="relative mb-2">
          <input 
            id="name"
            name="name"
            v-model="name"
            type="text"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('account.profile.general.name.placeholder')"
          />          
        </div>
          <div class="text-xs mt-2 text-red-400" v-if="!$v.name.$error">
              <div v-if="!$v.name.required">{{$t('account.profile.general.name.required')}}</div>
          </div>
      </div>
      <!-- Email -->
      <div class="mt-3">
        <div class="font-bold leading-3	mb-2">{{ $t('account.profile.general.email.title') }}</div>
        <div class="text-sm mb-2">{{ $t('account.profile.general.email.subtitle') }}</div>
        <div class="relative mb-2">
          <input 
            id="email"
            name="email"
            v-model="email"
            type="text"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('account.profile.general.email.placeholder')"
          />
          <div class="text-xs mt-2 text-red-400" v-if="!$v.email.$error">
              <div v-if="!$v.email.required">{{$t('account.profile.general.email.required')}}</div>                            
              <div v-if="!$v.email.email">{{$t('account.profile.general.email.format')}}</div>
          </div>          
        </div>
      </div>          
    </div>  
    <div class="px-6">
      <button
        v-on:click="onSaveChanges"
        class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        {{ $t('account.profile.save') }}
      </button>    
    </div>  
  </div>
</template>

<script>
import Vue from "vue";
import { required, email } from 'vuelidate/lib/validators'
import Navbar from "@/components/Navbar";
import UserType from '@/store/types/user';

export default {
  name: "profile",
  components: {    
    Navbar
  },
  data: function() {
    return {
      tabSelected: 'profile'
    };
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      email
    }
  },
  computed: {
    name: {
      get: function() {
        return this.$store.getters[UserType.getters.name];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setName, val);
      }
    },
    email: {
      get: function() {
        return this.$store.getters[UserType.getters.email];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setEmail, val);
      }
    },
  },
  methods: {
    onSaveChanges: async function() {
      this.$wait.start("global");
      try {
        await this.$store.dispatch(UserType.actions.updateUser);
        Vue.$toast.success(this.$t('account.profile.general.saveMessage'));
      } catch (err) {
        Vue.$toast.error(err.message);
      }
      this.$wait.end("global");
    }
  }
};
</script>
<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-12 px-4">
    <LogoIcon class="mb-8"/>
    <div class="max-w-md w-full border border-gray-200 pt-12 pb-16 px-12 rounded bg-white filter drop-shadow-lg">
      <div>
        <h2 class="text-center text-3xl font-bold text-gray-800 mb-6">
          {{$t('login.title')}}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{$t('login.subtitle')}}
          <a href="/register" class="font-semibold text-indigo-500 hover:text-indigo-600">
            {{$t('login.register')}}
          </a>
        </p>
      </div>
      <form 
        novalidate 
        class="mt-8 space-y-6"
        @submit.stop.prevent="handleSubmit"
      >
        <div class="rounded-md">
          <div class="py-2">
            <label for="email" class="sr-only">{{$t('login.form.email')}}</label>
            <input 
              v-model.trim="form.email" 
              :state="validateState('email')"
              id="email"
              name="email" 
              type="email" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.email.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('login.form.email')"
            >
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.email.$error">
              <div v-if="!$v.form.email.required">{{$t('login.form.emailRequired')}}</div>
              <div v-if="!$v.form.email.email">{{$t('login.form.emailFormat')}}</div>
            </div>
          </div>
          <div>
            <label for="password" class="sr-only">{{$t('login.form.password')}}</label>
            <div class="relative">
              <span v-on:click="togglePassword" class="absolute inset-y-0 right-2 flex items-center text-gray-400 w-5 cursor-pointer">
                <EyeOffIcon v-if="isPasswordVisible"/>
                <EyeOnIcon v-if="!isPasswordVisible"/>
              </span>
              <input 
                v-model.trim="form.password" 
                :state="validateState('password')"
                id="password" 
                name="password" 
                :type="isPasswordVisible ? 'text' : 'password'"
                autocomplete="off" 
                required 
                :class="{ 'border-red-400': $v.form.password.$error }"
                class="appearance-none rounded block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                :placeholder="$t('login.form.password')"/>
            </div>
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.password.$error">
              <div v-if="!$v.form.password.required">{{$t('login.form.passwordRequired')}}</div>
              <div v-if="!$v.form.password.minLength">{{$t('login.form.passwordMinLength')}}</div>
            </div>              
          </div>
        </div>

        <div class="flex items-center justify-end">
          <div class="text-sm">
            <a  href="/forgot" class="font-semibold text-indigo-600 hover:text-indigo-500">
              {{$t('login.forgotPassword')}}
            </a>
          </div>
        </div>

        <div>
          <button type="submit" class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            {{$t('login.form.submit')}}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import { required, email, minLength } from 'vuelidate/lib/validators'
import UserType from '@/store/types/user';
import LogoIcon from '@/components/icons/Logo';
import EyeOffIcon from '@/components/icons/EyeOff';
import EyeOnIcon from '@/components/icons/EyeOn';

export default {
  name: 'Login',
  components: {
    LogoIcon,
    EyeOffIcon,
    EyeOnIcon
  },
  data() {
    return {
      isPasswordVisible: false,
      form: {
        email: '',
        password: ''
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    togglePassword: function() {
      this.$data.isPasswordVisible = !this.$data.isPasswordVisible
    },  
    async handleSubmit() {            
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }
      
      try {
        await this.$store.dispatch(UserType.actions.login, this.$data.form);
        this.$router.push({ name: 'FormList' });
      } catch (err) {
        await this.$store.dispatch(UserType.actions.logout);
        Vue.$toast.error(err.message);
      }
    }
  },
};
</script>

<template>
  <div class="rounded p-4 text-sm mb-2 flex items-center justify-between border w-full">
    <div v-if="dropDownOpen" @click="toggleDropdown()" class="absolute inset-0 h-full w-full z-10"></div>
    <div class="font-semibold flex items-center w-3/4">
      <CreditCardIcon/>
      <span class="ml-2">
        {{ $t('account.settings.billing.paymentMethod.card') }} 
        {{card.cardBrand}} {{ $t('account.settings.billing.paymentMethod.endingIn') }} {{ card.lastFour}}
      </span>
    </div>
    <div class="w-1/4">
      {{card.expireMonth}} / {{card.expireYear}}
    </div>
    <div class="uppercase w-1/5">
      {{card.status}}
    </div>
    <div class="relative cursor-pointer">
      <button @click="toggleDropdown()">
        <DotsVerticalIcon/>
      </button>      
      <div v-if="dropDownOpen" class="absolute text-sm right-0 w-48 bg-white rounded-md border border-gray-300 shadow-xl z-20 divide-y">
        <a 
          v-if="card.status !== 'primary'"
          @click="onUpdateCard(card.slug, 'primary')"
          class="w-full flex items-center py-2 hover:bg-blue-500 hover:bg-opacity-20 px-4"
        >
          <span>Hacer Principal</span>
        </a>
        <a 
          v-if="count > 1"
          @click="onUpdateCard('disabled')" 
          class="w-full flex items-center py-2 hover:bg-blue-500 hover:bg-opacity-20 px-4 text-red-600"
        >
          <span>Eliminar</span>
        </a>        
      </div>
    </div>  
  </div>
</template>

<script>
import Vue from 'vue';
import DotsVerticalIcon from '@/components/icons/DotsVertical';
import CreditCardIcon from '@/components/icons/CreditCard';
import BillingType from '@/store/types/billing';

export default {
  name: "CreditCard",
  components: {
    DotsVerticalIcon,
    CreditCardIcon
  },
  props: {
    card: Object,
    count: Number
  },
  data() {
    return {
      dropDownOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.dropDownOpen = !this.dropDownOpen
    },
    async onUpdateCard(slug, status) {
      try {
        await this.$store.dispatch(BillingType.actions.updateCard, {
          slug,
          status
        });
      } catch (err) {
        Vue.$toast.error(err.message);
      }
    }
  }
}

</script>
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import VuexPersistence from 'vuex-persist';
import user from './modules/user';
import listForm from './modules/forms/list';
import showForm from './modules/forms/show';
import global from './modules/global';
import billing from './modules/billing';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
});

export default new Store({
  modules: {
    global,
    user,
    billing,
    listForm,
    showForm
  },
  plugins: [vuexLocal.plugin],
  strict: debug,
})
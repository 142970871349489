<template>
  <div>
    <Header/>
    <div class="flex flex-col max-w-screen-md px-6 mb-6 divide-y">
      <div class="pb-6">
        <div class="flex items-center justify-between py-5">
          <div>
            <div class="font-bold leading-3	mb-2">{{ $t('account.settings.billing.contactEmail.title') }}</div>
            <div class="text-sm">{{ $t('account.settings.billing.contactEmail.subtitle') }}</div>
          </div>
        </div>
        <div>
          <div class="mb-6">
            <input 
              class="rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none mt-1 align-top mr-2 cursor-pointer" 
              type="radio"
              v-bind:value="false"
              v-model="sendToAlternativeBillingEmail"
              id="sendToAlternativeBillingEmail-1"
            >
            <label class="inline-block text-gray-800" for="sendToAlternativeBillingEmail-1">
              <div class="font-bold text-sm">{{ $t('account.settings.billing.contactEmail.sameAccount') }}</div>
              <div class="text-sm">{{ user.email }}</div>
            </label>
          </div>
          <div class="">
            <input 
              class="rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none mt-1 align-top mr-2 cursor-pointer" 
              type="radio" 
              v-bind:value="true"
              v-model="sendToAlternativeBillingEmail"
              id="sendToAlternativeBillingEmail-2"
            >
            <label class="inline-block text-gray-800 w-2/3" for="sendToAlternativeBillingEmail-2">
              <div class="font-bold text-sm">{{ $t('account.settings.billing.contactEmail.alternativeEmail') }}</div>
              <input
                id="alternativeBillingEmail"
                name="alternativeBillingEmail"
                v-model="alternativeBillingEmail"
                type="email"
                class="appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                :placeholder="$t('account.profile.general.email.placeholder')"
              />            
            </label>
          </div>                
        </div>      
      </div>

      <div class="pb-6">
        <div class="flex items-center justify-between py-5">
          <div>
            <div class="font-bold leading-3	mb-2">{{ $t('account.settings.billing.paymentMethod.title') }}</div>
            <div class="text-sm">{{ $t('account.settings.billing.paymentMethod.subtitle') }}</div>
          </div>
        </div>
        <div>
          <div v-if="!cards || cards.length === 0">{{ $t('account.settings.billing.paymentMethod.noCard') }}</div>
          <CreditCard v-bind:card="card" v-bind:count="cards.length" v-for="card in cards" v-bind:key="card.slug"/>

          <button @click="$modal.show('addPaymentMethod')" class="flex mt-6 underline">
            <PlusCircleIcon class="h-6"/>
            {{ $t('account.settings.billing.paymentMethod.addPaymentMethod') }}
          </button>
        </div>
      </div>

      <div class="pt-8">
        <button
          v-on:click="onSaveChanges"
          class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {{$t('account.settings.billing.paymentMethod.saveChanges')}}
        </button>
      </div>
    </div>    
    <modal name="addPaymentMethod"
      :height="480"
      :width="400"
      :adaptive="true">
      <div class="rounded-md flex flex-col justify-between h-full">
        <div>
          <div class="w-full py-4 px-2 border-b border-gray-200 text-gray-100 mb-2 bg-deep-blue">
            {{ $t('account.settings.billing.paymentMethod.modal.title') }}
          </div>
          <div class="p-4 pt-1">
            <p class="text-xs mb-2 leading-relaxed border-b pb-2 font-extralight">
              {{ $t('account.settings.billing.paymentMethod.modal.temporaryCharge') }}
            </p>
            <label for="cardNumber" class="leading-3 mb-2 text-xs font-semibold">
              {{ $t('account.settings.billing.paymentMethod.modal.cardNumber.label') }}
            </label>
            <div class="relative">
              <span class="absolute inset-y-0 right-2 flex items-center text-gray-400 w-5 z-10 cursor-pointer">
                <CreditCardIcon/>
              </span>
              <the-mask
                id="cardNumber"
                name="cardNumber"
                v-model.trim="card.cardNumber"
                mask="#### #### #### ####"
                type="text"
                class="appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :placeholder="$t('account.settings.billing.paymentMethod.modal.cardNumber.placeholder')"
              />
            </div>
          </div>
          <div class="p-4 pt-1 flex">
            <div class="mr-2">
              <label for="expireDate" class="leading-3	mb-2 text-xs font-semibold">
                {{ $t('account.settings.billing.paymentMethod.modal.expireDate.label') }}
              </label>
              <the-mask
                id="expireDate"
                name="expireDate"
                v-model.trim="card.expireDate"
                mask="## / ####"
                :masked="true"
                type="text"
                class="appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                :placeholder="$t('account.settings.billing.paymentMethod.modal.expireDate.placeholder')"
              />
            </div>
            <div>
              <label for="cvv" class="leading-3	mb-2 text-xs font-semibold">
                {{ $t('account.settings.billing.paymentMethod.modal.cvv.label') }}
              </label>
              <the-mask
                id="cvv"
                name="cvv"
                v-model.trim="card.cvv"
                type="text"
                mask="####"
                :masked="true"
                class="appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                :placeholder="$t('account.settings.billing.paymentMethod.modal.cvv.placeholder')"
              />
            </div>
          </div>
          <div class="p-4 pt-1">
            <label for="cardEmail" class="leading-3	mb-2 text-xs font-semibold">
              {{ $t('account.settings.billing.paymentMethod.modal.email.label') }}
            </label>
            <input
              id="cardEmail"
              name="cardEmail"
              v-model.trim="card.email"
              type="text"
              class="appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              :placeholder="$t('account.settings.billing.paymentMethod.modal.email.placeholder')"
            />
            <div class="text-xs mt-2 text-red-400" v-if="$v.card.email.$error">
              <div v-if="!$v.card.email.required">{{$t('account.settings.billing.paymentMethod.modal.email.emailRequired')}}</div>
              <div v-if="!$v.card.email.email">{{$t('account.settings.billing.paymentMethod.modal.email.emailFormat')}}</div>
            </div>          
          </div>
        </div>
        <div class="p-4 pt-2">
          <button 
            v-on:click="addPaymentMethod"
            class="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{$t('account.settings.billing.paymentMethod.modal.save')}}
          </button>
        </div>
      </div>
    </modal>  
  </div>
</template>

<script>
import Vue from 'vue';
import { required, email } from 'vuelidate/lib/validators'
import Header from '@/pages/accounts/Header';
import UserType from '@/store/types/user';
import BillingType from '@/store/types/billing';
import { mapGetters } from 'vuex';
import PlusCircleIcon from '@/components/icons/PlusCircle';
import CreditCard from '@/pages/accounts/CreditCard';

export default {
  name: "BillingInformation",
  components: {
    Header,
    PlusCircleIcon,
    CreditCard
  },
  data() {
    return {      
      card: {
        email: '',
        expireDate: '',
        cvv: '',
        cardNumber: ''
      }
    };
  },  
  validations: {    
    card: {
      email: {
        required,
        email
      },
      expireDate: {
        required
      },
      cvv: {
        required
      },
      cardNumber: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      user: UserType.getters.getUser,
      cards: BillingType.getters.cards
    }),
    sendToAlternativeBillingEmail: {
      get: function() {
        return this.$store.getters[UserType.getters.sendToAlternativeBillingEmail];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setSendToAlternativeBillingEmail, val);
      }
    },
    alternativeBillingEmail: {
      get: function() {
        return this.$store.getters[UserType.getters.alternativeBillingEmail];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setAlternativeBillingEmail, val);
      }
    },    
  },
  methods: {
    onSaveChanges: async function() {
      this.$wait.start("global");
      try {
        await this.$store.dispatch(UserType.actions.updateBillingInformation);
        Vue.$toast.success(this.$t('form.show.settings.saveMessage'));
      } catch (err) {
        Vue.$toast.error(err.message);
      }
      this.$wait.end("global");
    },
    addPaymentMethod: async function() {
      this.$wait.start("global");
      try {
        
        await this.$store.dispatch(BillingType.actions.addCard, this.$data.card);
        this.$modal.hide('addPaymentMethod');
        Vue.$toast.success(this.$t('account.settings.billing.paymentMethod.modal.saveMessage'));

        // Cleanup
        this.$data.card = {
          email: '',
          expireDate: '',
          cvv: '',
          cardNumber: ''          
        }
        // Reload Cards
        await this.$store.dispatch(BillingType.actions.listCards);
      } catch (err) {
        Vue.$toast.error(err.message);
      }
      this.$wait.end("global");      
    }
  },
  async mounted() {
    this.$wait.start("global");
    try {
      await this.$store.dispatch(BillingType.actions.listCards);
    } catch (err) {
      Vue.$toast.error(err.message);
    }
    this.$wait.end("global");
  },
};
</script>
<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex flex-wrap">
      <Sidebar />
      <div id="main-content" :class="sideBarOpen ? 'overlay' : ''" class="w-full bg-white pl-0 lg:pl-64 min-h-screen flex flex-col">
        <div class="flex flex-col flex-grow">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Sidebar from '@/components/Sidebar'

export default {
  name: 'Dashboard',
  computed: {
    ...mapState(['sideBarOpen'])
  },
  components: {
    Sidebar
  }
}
</script>

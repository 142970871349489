<template>
  <div>
    <Navbar :title="$t('account.settings.title')"/>
    <div class="flex border-b mb-6 px-6">
      <router-link 
        :to="{ name: 'plan'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'plan'}"
      >
        {{$t('account.settings.headers.plan')}}
      </router-link>
      <router-link 
        :to="{ name: 'invoice'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'invoice'}"
      >
        {{$t('account.settings.headers.invoice')}}
      </router-link>      
      <router-link 
        :to="{ name: 'paymentMethod'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'paymentMethod'}"
      >
        {{$t('account.settings.headers.paymentMethod')}}
      </router-link>
      <router-link 
        :to="{ name: 'billingHistory'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'billingHistory'}"
      >
        {{$t('account.settings.headers.billingHistory')}}
      </router-link>     
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "BillingHistory",
  components: {
    Navbar
  },
  computed: {

  },
  filters: {
  
  },
  async mounted() {

  },
};
</script>
<template>
  <div id="app" class="font-montserrat">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

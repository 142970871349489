<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-12 px-4">
    <LogoIcon class="mb-8"/>
    <div class="max-w-md w-full border border-gray-200 pt-12 pb-16 px-12 rounded bg-white filter drop-shadow-lg">
      <div>
        <h2 class="text-center text-3xl font-bold text-gray-800 mb-6">
          {{$t('activate.title')}}
        </h2>
        <p class="mt-4 text-center text-md text-gray-600">
          <span v-if="!loading && successful">
            {{$t('activate.successful')}}
          </span>
          <span v-if="!loading && error" class="text-pink-600">
            {{$t('activate.error')}}
          </span>
          <span v-if="loading">
            {{$t('activate.subtitle')}}
          </span>
        </p>
        <p class="mt-8 text-center text-md text-blue-600">
          <vue-element-loading 
            :active='$wait.waiting("activate")'
            spinner="bar-fade-scale" />
          <router-link to="/login"
            class="font-semibold text-indigo-600 hover:text-indigo-500">
            {{$t('register.login')}}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import UserType from '@/store/types/user';
import LogoIcon from '@/components/icons/Logo';

export default {
  name: 'Login',
  components: {
    LogoIcon
  },  
  data() {
    return {
      loading: true,
      successful: false,
      error: false,
    };
  },
  mounted() {
    this.$wait.start("activate");
    this.handleSubmit();
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch(UserType.actions.activate, this.$route.params.token);
        this.successful = true;
        this.error = false;
      } catch (err) {
        this.successful = false;
        this.error = true;
        Vue.$toast.error(err.message);
      }
      this.loading = false;
      this.$wait.end("activate");
    }
  },
};
</script>

<template>
  <div 
    id="main-nav" 
    :class="sideBarOpen ? '' : 'hidden'" 
    class="w-1/2 md:w-1/3 lg:w-64 fixed md:top-0 md:left-0 h-screen lg:block bg-deep-black text-gray-100 border-r z-50 text-sm"
  >

    <div class="w-full flex p-4 items-center pt-6 pb-6 mb-2">
      <LogoInverseIcon/>
    </div>
    <div class="mb-4">
      <p class="font-semibold mb-1 px-4">{{$t('dashboard.sidebar.workspaces.title')}}</p>
      <router-link 
        to="/forms"
        active-class="bg-blue-500 bg-opacity-20 border-l-4 border-deep-yellow text-deep-yellow"
        class="w-full flex items-center py-2 hover:bg-blue-500 hover:bg-opacity-20 px-4"
      >
        <HomeIcon class="h-6 w-6 fill-current mr-2"/>
        <span>{{$t('dashboard.sidebar.workspaces.myForms')}}</span>
      </router-link>
    </div>

    <div class="mt-16 mb-4">
      <p class="font-semibold mb-1 px-4">{{$t('dashboard.sidebar.documentation.title')}}</p>
      <a href="https://docs.simpleapiform.com/es/quick-start/" class="w-full flex items-center py-2 hover:bg-blue-500 hover:bg-opacity-20 px-4" target="_blank">
        <BeakerIcon class="h-6 w-6 mr-2"/>
        <span >{{$t('dashboard.sidebar.documentation.getStarted')}}</span>  
      </a>
    </div>  
  </div>
</template>

<script>
import LogoInverseIcon from "@/components/icons/LogoInverse";
import HomeIcon from "@/components/icons/Home";
import BeakerIcon from "@/components/icons/Beaker";

export default {
  name: "Sidebar",
  components: {
    HomeIcon,    
    BeakerIcon,
    LogoInverseIcon
  },
  data: function() {
    return {
      route: this.$router.history.current.name
    }
  },
  computed: {
    sideBarOpen() {
      return this.$store.getters.sideBarOpen;
    }
  }
};
</script>

//import jwtDecode from 'jwt-decode';
import injector from 'vue-inject';
import BillingType from '../types/billing';

const state = {
  cards: [],
};

const getters = {
  [BillingType.getters.cards](state) {
    return state.cards;
  }
};

const mutations = {
  [BillingType.mutations.setCards](state, cards) {
    state.cards = cards;
  },
  [BillingType.mutations.updateCard](state, card) {
    const index = state.cards.findIndex((obj => obj.slug === card.slug));
    state.cards[index] = card;
  }
};

const actions = {
  [BillingType.actions.addCard]: injector.encase(['CulqiService', 'BillingService'], (CulqiService, BillingService) => async (_, cardData) => {
    
    const [expiration_month, expiration_year] = cardData.expireDate.split('/'); 

    const tokenData = await CulqiService.createToken({
      email: cardData.email,
      card_number: cardData.cardNumber,
      cvv: cardData.cvv,
      expiration_year: expiration_year.trim(),
      expiration_month: expiration_month.trim()
    }, process.env.VUE_APP_CULQI_PUBLIC_KEY);

    const { card } = await BillingService.addCard({
      ...tokenData,
      expireMonth: expiration_month.trim(),
      expireYear: expiration_year.trim(),
      status: 'primary'
    });

    return card;
  }),
  [BillingType.actions.listCards]: injector.encase(['BillingService'], (BillingService) => async ({ commit }) => {
    
    const { cards } = await BillingService.listCards();
    commit(BillingType.mutations.setCards, cards);
    return cards;
  }),
  [BillingType.actions.updateCard]: injector.encase(['BillingService'], (BillingService) => async ({ commit }, cardData) => {
    const card = await BillingService.updateCard(cardData);
    commit(BillingService.mutations.updateCard, card);
    return card;
  })
};

export default {
  state,
  getters,
  mutations,
  actions,
};

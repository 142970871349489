<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-12 px-4">
    <LogoIcon class="mb-8"/>
    <div class="max-w-md w-full border border-gray-200 pt-12 pb-16 px-12 rounded bg-white filter drop-shadow-lg">
      <div>
        <h2 class="text-center text-3xl font-bold text-gray-800 mb-6">
          {{$t('register.title')}}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{$t('register.subtitle')}}
          <a href="/login" class="font-semibold text-indigo-600 hover:text-indigo-500">
            {{$t('register.login')}}
          </a>
        </p>
      </div>
      <form 
        novalidate 
        class="mt-8 space-y-6"
        @submit.stop.prevent="handleSubmit"
      >
        <div class="rounded-md">
          <div class="py-2">
            <label for="name" class="sr-only">{{$t('register.form.name')}}</label>
            <input 
              v-model.trim="form.name" 
              :state="validateState('name')"
              id="name"
              name="name" 
              type="text"
              autocomplete="off" 
              required
              :class="{ 'border-red-400': $v.form.name.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('register.form.name')"
            >
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.name.$error">
              <div v-if="!$v.form.name.required">
                {{$t('register.form.nameRequired')}}
              </div>
            </div>
          </div>          
          <div class="py-2">
            <label for="email" class="sr-only">{{$t('register.form.email')}}</label>
            <input 
              v-model.trim="form.email" 
              :state="validateState('email')"
              id="email"
              name="email" 
              type="email" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.email.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('register.form.email')"
            >
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.email.$error">
              <div v-if="!$v.form.email.required">{{$t('register.form.emailRequired')}}</div>                            
              <div v-if="!$v.form.email.email">{{$t('register.form.emailFormat')}}</div>
            </div>
          </div>
          <div>
            <label for="password" class="sr-only">{{$t('register.form.password')}}</label>
            <input 
              v-model.trim="form.password" 
              :state="validateState('password')"
              id="password" 
              name="password" 
              type="password" 
              autocomplete="off" 
              required 
              :class="{ 'border-red-400': $v.form.password.$error }"
              class="appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
              :placeholder="$t('register.form.password')"
            >
            <div class="text-xs mt-2 text-red-400" v-if="$v.form.password.$error">
              <div v-if="!$v.form.password.required">{{$t('register.form.passwordRequired')}}</div>                            
              <div v-if="!$v.form.password.minLength">{{$t('register.form.passwordMinLength')}}</div>              
            </div>              
          </div>
        </div>

        <div>
          <button type="submit" class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>            
            </span>
            {{$t('register.form.submit')}}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import { required, email, minLength } from 'vuelidate/lib/validators';
import UserType from '@/store/types/user';
import LogoIcon from '@/components/icons/Logo';

export default {
  name: 'Login',
  components: {
    LogoIcon
  },  
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: ''
      }
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },    
    async handleSubmit() {            
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }
      
      try {
        await this.$store.dispatch(UserType.actions.register, this.$data.form);
        this.$router.push({ path: '/' });
      } catch (err) {
        await this.$store.dispatch(UserType.actions.logout);        
        Vue.$toast.error(err.message);        
      }
    }
  },
};
</script>

<template>
  <div>
    <Navbar :title="$t('account.profile.title')"/>
    <div class="flex border-b mb-6 px-6">
      <router-link 
        :to="{ name: 'profile'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'profile'}"
      >
        {{$t('account.profile.headers.general')}}
      </router-link>
      <router-link 
        :to="{ name: 'password'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'password'}"
      >
        {{$t('account.profile.headers.password')}}
      </router-link>      
    </div>
    <div class="flex flex-col max-w-screen-md px-6 mb-6">
      <!-- Current Password -->
      <div class="mt-3">
        <div class="font-bold leading-3	mb-2">{{ $t('account.profile.password.currentPassword.title') }}</div>
        <div class="text-sm mb-2">{{ $t('account.profile.password.currentPassword.subtitle') }}</div>
        <div class="relative mb-2">
          <input 
            id="currentPassword"
            name="currentPassword"
            v-model="currentPassword"
            type="password"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('account.profile.password.currentPassword.placeholder')"
          />
          <div class="text-xs mt-2 text-red-400" v-if="!$v.currentPassword.$error">
            <div v-if="!$v.currentPassword.required">{{$t('account.profile.password.currentPassword.required')}}</div>
            <div v-if="!$v.currentPassword.minLengthValue">{{$t('account.profile.password.currentPassword.minLength')}}</div>
          </div> 
        </div>
      </div>
      <!-- New Password -->
      <div class="mt-3">
        <div class="font-bold leading-3	mb-2">{{ $t('account.profile.password.newPassword.title') }}</div>
        <div class="text-sm mb-2">{{ $t('account.profile.password.newPassword.subtitle') }}</div>
        <div class="relative mb-2">
          <input 
            id="newPasword"
            name="newPasword"
            v-model="newPassword"
            type="password"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('account.profile.password.newPassword.placeholder')"
          />
          <div class="text-xs mt-2 text-red-400" v-if="!$v.newPassword.$error">
            <div v-if="!$v.newPassword.required">{{$t('account.profile.password.newPassword.required')}}</div>
            <div v-if="!$v.newPassword.minLengthValue">{{$t('account.profile.password.currentPassword.minLength')}}</div>
          </div>          
        </div>
      </div>
      <!-- Confirm Password -->
      <div class="mt-3">
        <div class="font-bold leading-3	mb-2">{{ $t('account.profile.password.confirmPassword.title') }}</div>
        <div class="text-sm mb-2">{{ $t('account.profile.password.confirmPassword.subtitle') }}</div>
        <div class="relative mb-2">
          <input 
            id="confirmPassword"
            name="confirmPassword"
            v-model="confirmPassword"
            type="password"                  
            class=" appearance-none rounded relative block w-full pl-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            :placeholder="$t('account.profile.password.confirmPassword.placeholder')"
          />
          <div class="text-xs mt-2 text-red-400" v-if="!$v.confirmPassword.$error">
            <div v-if="!$v.confirmPassword.required">{{$t('account.profile.password.confirmPassword.required')}}</div>
            <div v-if="!$v.confirmPassword.minLengthValue">{{$t('account.profile.password.currentPassword.minLength')}}</div>
          </div>          
        </div>
      </div>                 
    </div>  
    <div class="px-6">
      <button
        v-on:click="onSaveChanges"
        class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        {{ $t('account.profile.updatePassword') }}
      </button>
    </div>  
  </div>
</template>

<script>
import Vue from "vue";
import Navbar from "@/components/Navbar";
import UserType from '@/store/types/user';
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: "password",
  components: {    
    Navbar
  },
  data: function() {
    return {
      tabSelected: 'password'
    };
  },
  validations: {
    currentPassword: {
      required,
      minLengthValue: minLength(8)
    },
    newPassword: {
      required,
      minLengthValue: minLength(8)
    },
    confirmPassword: {
      required,
      minLengthValue: minLength(8)
    }
  },    
  computed: {
    currentPassword: {
      get: function() {
        return this.$store.getters[UserType.getters.currentPassword];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setCurrentPassword, val);
      }
    },
    newPassword: {
      get: function() {
        return this.$store.getters[UserType.getters.newPassword];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setNewPassword, val);
      }
    },
    confirmPassword: {
      get: function() {
        return this.$store.getters[UserType.getters.confirmPassword];
      },
      set: function(val) {
        this.$store.commit(UserType.mutations.setConfirmPassword, val);
      }
    },
  },
  methods: {
    onSaveChanges: async function() {
      this.$wait.start("global");
      try {

        if (this.$v.currentPassword.$invalid ||
            this.$v.newPassword.$invalid ||
            this.$v.confirmPassword.$invalid) {
          return Vue.$toast.warning(this.$t('account.profile.password.errors'));
        }        

        if (this.newPassword != this.confirmPassword) {
          return Vue.$toast.error(this.$t('account.profile.password.sameAs'))
        }

        await this.$store.dispatch(UserType.actions.updatePassword);
        Vue.$toast.success(this.$t('account.profile.password.saveMessage'));

      } catch (err) {
        Vue.$toast.error(err.message);
      }
      this.$wait.end("global");
    }
  }
};
</script>
export default {
  dashboard: {
    navbar: {
      documentation: 'Documentación',
      back: 'Regresar',
    },
    sidebar: {
      workspaces: {
        title: 'ESPACIOS',
        myForms: 'Mis Formularios'
      },
      team: {
        title: 'EQUIPOS',
        invite: 'Invitar miembros',
        myteam: 'Mi equipo'
      },
      account: {
        title: 'CUENTA',
        profile: 'Mi Perfil',
        config: 'Configuración',
        logout: 'Cerrar Sesión',
      },
      documentation: {
        title: 'DOCUMENTACIÓN',
        getStarted: 'Instalación básica'
      }
    }
  },
  login: {
    title: 'Hola, bienvenido',
    subtitle: '¿No tienes una cuenta?',
    register: 'Registrate aquí',
    forgotPassword: '¿Olvidaste tu contraseña?',
    form: {
      email: 'Correo electrónico',
      emailRequired: 'El campo es requerido',
      emailFormat: 'Ingrese un formato válido',
      // file deepcode ignore NoHardcodedPasswords: it's only a language label
      password: 'Contraseña',
      passwordRequired: 'El campo es requerido',
      passwordMinLength: 'El campo debe tener al menos 8 caracteres',
      submit: 'Ingresar'      
    }
  },
  register: {
    title: 'Crea tu cuenta',
    subtitle: '¿Ya tienes una cuenta?',
    login: 'Ingresa aquí',
    form: {
      name: 'Nombre completo',
      nameRequired: 'El campo es requerido',
      email: 'Correo electrónico',
      emailRequired: 'El campo es requerido',
      emailFormat: 'Ingrese un formato válido',
      password: 'Contraseña',
      passwordRequired: 'El campo es requerido',
      passwordMinLength: 'El campo debe tener al menos 6 caracteres',
      submit: 'Registrar'      
    }
  },
  forgot: {
    title: 'Restablecer contraseña',
    subtitle: 'Ingresa el correo electrónico asociado a tu cuenta y te enviaremos un link para poder reiniciar tu contraseña',
    title2: 'Gracias, revisa tu correo electrónico para más instrucciones',
    subtitle2: '¿No te llegó el correo? Revisa la carpeta de correo no deseado o ',
    resend: 'vuelve a enviarlo',
    form: {
      email: 'Correo electrónico',
      emailRequired: 'El campo es requerido',
      emailFormat: 'Ingrese un formato válido',
      submit: 'Enviar'
    },
  },
  reset: {
    title: 'Reiniciar contraseña',
    successTitle: 'La contraseña ha sido actualizada con éxito',
    continueToLogin: 'Ingresar aquí',    
    form: {
      password: 'Contraseña',
      passwordRequired: 'El campo es requerido',
      passwordMinLength: 'El campo debe tener al menos 6 caracteres',
      confirmPassword: 'Confirmar Contraseña',
      confirmPasswordRequired: 'El campo es requerido',
      confirmPasswordMinLength: 'El campo debe tener al menos 6 caracteres',
      confirmPasswordSame: 'Ambos campos deben ser iguales',
      submit: 'Reiniciar Contraseña'
    }
  },
  form: {
    list: {
      create: 'Crear Formulario',
      closed: 'Cerrado',
      submissions: 'Sin envíos | un envío | {count} envíos',
      headers: {
        name: 'Nombre',
        responses: '# Respuestas',
        active: 'Activo',
        creationDate: 'Fecha creación'
      },
      created: 'Formulario Creado',
    },
    show: {
      headers: {
        responses: 'Respuestas',
        share: 'Compartir',
        integrations: 'Integraciones',
        settings: 'Configuración'
      },
      responses: {
        count: 'Sin respuestas | 1 respuesta en total | {count} respuestas en total',
        export: 'Descargar CSV',
        submittedAt: 'Hora Envio',
        empty: 'No hay respuestas asociadas a este formulario',
        page: 'Página'
      },
      share: {
        title: 'API endpoint',
        subtitle: 'Usa este URL en el action de tu formulario. Asegurate de usar method=POST',
        copy: 'Copiar',
        copiedMessage: 'Enlace copiado',
        failToCopiedMessage: 'Hubo un error al copiar el enlace',
        instructions: 'Ver la guía de instalación para más ejemplos'
      },
      integrations: {
        sheets: {
          title: 'Google Sheets',
          subtitle: 'Sincroniza tus respuestas con google sheet',
          connect: 'Conectar'
        },
      },
      settings: {
        name: {
          title: 'Nombre Formulario',
          subtitle: 'Cambia el nombre del formulario',
          placeholder: 'Nombre del formulario'
        },
        email: {
          title: 'Notificaciones por Email',
          subtitle: 'Recibe un correo electrónico por cada respuesta enviada'
        },
        redirect: {
          title: 'Redirigir en envio',
          subtitle: 'Redirigir a una URL personalizada cuando se envie el formulario',
          urlRequired: 'Formato inválido. Sugerencia: Incluir el protocolo (http) al principio',
          placeholder: 'midominio.com'
        },
        close: {
          title: 'Cerrar formulario',
          subtitle: 'No se podrá enviar más datos a este formulario'
        },
        recaptcha: {
          title: 'Protección reCAPTCHA',
          subtitle: 'Protege el formulario usando reCaptcha',
          placeholder: 'Google reCAPTCHA llave secreta'
        },
        save: 'Guardar cambios',
        saveMessage: 'Datos guardados con éxito'
      }
    }
  },
  account: {
    profile: {
      title: 'Mi Perfil',      
      save: 'Guardar Cambios',
      updatePassword: 'Actualizar contraseña',
      headers: {
        general: 'Datos generales',
        password: 'Contraseña'
      },
      general: {
        saveMessage: 'Datos guardados con éxito',
        name: {
          placeholder: 'Juan Perez',
          title: 'Nombre',
          subtitle: 'Ingresa tu nombre, para que las personas puedan reconocerte',
          required: 'El campo es requerido'
        },
        email: {
          placeholder: 'juan@simpleapiform.com',
          title: 'Email',
          subtitle: 'Tu correo es tu identificador principal de tu cuenta',
          required: 'El campo es requerido',
          format: 'Ingrese un formato válido'          
        }
      },
      password: {
        saveMessage: "La contraseña fue actualizada con éxito",
        errors: 'Corregir todos los errores antes de enviar',
        sameAs: 'La nueva contraseña y la confirmación deben ser iguales',
        currentPassword: {
          placeholder: '',
          title: 'Contraseña actual',
          subtitle: 'Ingresa tu password actual',
          required: 'El campo es requerido',
          minLength: 'El campo debe tener mínimo 8 caracteres'
        },
        newPassword: {
          placeholder: '',
          title: 'Nueva contraseña',
          subtitle: 'Ingresa tu nueva contraseña',
          required: 'El campo es requerido',
          minLength: 'El campo debe tener mínimo 8 caracteres'
        },
        confirmPassword: {
          placeholder: '',
          title: 'Confirma contraseña',
          subtitle: 'Vuelve a ingresar tu nueva contraseña',
          required: 'El campo es requerido',
          minLength: 'El campo debe tener mínimo 8 caracteres'
        }
      }
    },
    settings: {
      title: 'Configuración cuenta',
      headers: {
        billingPlan: 'Plan',
        billingInformation: 'Información de Facturación',
        billingHistory: 'Historial de Facturación'
      },
      billing: {
        contactEmail: {
          title: 'Email de facturación',
          subtitle: 'Dirección de correo a donde enviaremos las facturas',
          sameAccount: 'Enviar al correo asociado a mi usuario',
          alternativeEmail: 'Enviar a este correo alternativo'
        },
        paymentMethod: {
          title: 'Métodos de pago',
          subtitle: 'Tarjetas registradas en el sistema',
          noCard: 'No tiene ningún medio de pago asociado',
          card: 'Tarjeta',
          endingIn: ' termina en ',
          expires: 'expira',
          addPaymentMethod: 'Agregar un método de pago',
          saveChanges: 'Guardar cambios',
          modal: {
            title: 'Agregar nuevo método de pago',
            validCardBrands: 'Aceptamos Visa, Mastercard, American Express y Discover.',
            temporaryCharge: 'Puede que vea un cargo en su tarjeta para validar la misma, este cargo será anulado al final del registro.',
            save: 'Agregar tarjeta',
            saveMessage: 'La tarjeta fue agregada exitosamente',
            cardNumber: {
              label: 'Número de tarjeta',
              placeholder: '0000 0000 0000 0000'
            },
            expireDate: {
              label: 'Vencimiento',
              placeholder: 'MM/AAAA'
            },
            cvv: {
              label: 'Código de seguridad',
              placeholder: 'CVC/CVV'
            },
            email: {
              label: 'Correo electrónico',
              placeholder: 'user@mail.com',
              emailRequired: 'El campo es requerido',
              emailFormat: 'Ingrese un formato válido',
            }
          }
        }
      },
      history: {

      }
    }
  },
  activate: {
    title: 'Activación de cuenta',
    subtitle: 'Estamos activando tu cuenta.',
    successful: 'Logramos activar tu cuenta.',
    error: 'No hemos logrado activar tu cuenta.'
  }
};

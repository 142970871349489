<template>
  <svg width="184" height="40" viewBox="0 0 184 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.2954 28.3934H5.57406H5.31177L5.57406 32.0656L7.93472 34.4262H27.8691L26.2954 28.3934Z" fill="#F4C240"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.918 9.5082C12.918 7.91472 14.2098 6.62295 15.8033 6.62295H25.7705V4H15.8033C12.7612 4 10.2951 6.4661 10.2951 9.5082V27.082H5.31148C4.58717 27.082 4 27.6691 4 28.3934V30.4918C4 33.5339 6.4661 36 9.50819 36H13.1803H18.6885H30.4918C33.5339 36 36 33.5339 36 30.4918V14.2295H33.377V30.4918C33.377 32.0853 32.0853 33.377 30.4918 33.377C28.8983 33.377 27.6066 32.0853 27.6066 30.4918V28.3934C27.6066 27.6691 27.0194 27.082 26.2951 27.082H12.918V9.5082ZM24.9836 29.7049H12.918H10.2951H6.62295V30.4918C6.62295 32.0853 7.91472 33.377 9.50819 33.377H10.3068H13.1803H15.8033H18.6885H25.7988C25.2818 32.5379 24.9836 31.5497 24.9836 30.4918V29.7049ZM17.1147 11.082C16.3904 11.082 15.8033 11.6691 15.8033 12.3934C15.8033 13.1178 16.3904 13.7049 17.1147 13.7049H20.7869C21.5112 13.7049 22.0984 13.1178 22.0984 12.3934C22.0984 11.6691 21.5112 11.082 20.7869 11.082H17.1147ZM17.1147 16.3279C16.3904 16.3279 15.8033 16.915 15.8033 17.6393C15.8033 18.3637 16.3904 18.9508 17.1147 18.9508H26.0328C26.7571 18.9508 27.3443 18.3637 27.3443 17.6393C27.3443 16.915 26.7571 16.3279 26.0328 16.3279H17.1147ZM15.8033 22.8852C15.8033 22.1609 16.3904 21.5738 17.1147 21.5738H29.1803C29.9046 21.5738 30.4918 22.1609 30.4918 22.8852C30.4918 23.6096 29.9046 24.1967 29.1803 24.1967H17.1147C16.3904 24.1967 15.8033 23.6096 15.8033 22.8852Z" fill="#1F2937"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3771 11.6066H36V4H28.3935V6.62295H31.8033L27.8689 10.5574L29.7236 12.4121L33.3771 8.75858V11.6066Z" fill="#6267EC"/>
    <path d="M53.3599 16.595H55.4669C55.4033 14.2758 53.3528 12.593 50.4114 12.593C47.5053 12.593 45.278 14.2546 45.278 16.7506C45.278 18.7657 46.7205 19.9465 49.0467 20.5758L50.7578 21.0425C52.3063 21.4526 53.5013 21.9617 53.5013 23.2486C53.5013 24.6627 52.1508 25.596 50.2912 25.596C48.6084 25.596 47.2084 24.8465 47.0811 23.2698H44.8892C45.0306 25.893 47.0599 27.5122 50.3053 27.5122C53.7063 27.5122 55.6649 25.7233 55.6649 23.2698C55.6649 20.6607 53.3387 19.6496 51.5003 19.197L50.0861 18.8294C48.9548 18.5395 47.4488 18.0092 47.4558 16.6233C47.4558 15.393 48.5801 14.4809 50.3619 14.4809C52.0235 14.4809 53.2043 15.2587 53.3599 16.595Z" fill="#1F2937"/>
    <path d="M57.395 27.2718H59.5091V16.4112H57.395V27.2718ZM58.4626 14.7354C59.1909 14.7354 59.799 14.1698 59.799 13.4768C59.799 12.7839 59.1909 12.2112 58.4626 12.2112C57.7273 12.2112 57.1263 12.7839 57.1263 13.4768C57.1263 14.1698 57.7273 14.7354 58.4626 14.7354Z" fill="#1F2937"/>
    <path d="M61.6422 27.2718H63.7563V20.5758C63.7563 19.1122 64.7816 18.094 65.9836 18.094C67.1573 18.094 67.9705 18.8718 67.9705 20.0526V27.2718H70.0775V20.3496C70.0775 19.0415 70.8765 18.094 72.2553 18.094C73.3725 18.094 74.2917 18.7162 74.2917 20.1869V27.2718H76.4058V19.989C76.4058 17.5071 75.02 16.2698 73.0543 16.2698C71.4917 16.2698 70.3179 17.0193 69.7947 18.1789H69.6816C69.2078 16.991 68.2109 16.2698 66.7614 16.2698C65.326 16.2698 64.2584 16.9839 63.8058 18.1789H63.6715V16.4112H61.6422V27.2718Z" fill="#1F2937"/>
    <path d="M78.5283 31.3445H80.6424V25.5819H80.7697C81.1515 26.2748 81.9293 27.4839 83.9091 27.4839C86.5394 27.4839 88.4485 25.3768 88.4485 21.8627C88.4485 18.3415 86.5111 16.2698 83.8879 16.2698C81.8727 16.2698 81.1445 17.5001 80.7697 18.1718H80.5929V16.4112H78.5283V31.3445ZM80.6 21.8415C80.6 19.5718 81.5899 18.0657 83.4354 18.0657C85.3515 18.0657 86.3131 19.6849 86.3131 21.8415C86.3131 24.0193 85.3232 25.6809 83.4354 25.6809C81.6182 25.6809 80.6 24.1253 80.6 21.8415Z" fill="#1F2937"/>
    <path d="M92.2202 12.791H90.1061V27.2718H92.2202V12.791Z" fill="#1F2937"/>
    <path d="M99.0341 27.491C101.403 27.491 103.079 26.3243 103.559 24.5566L101.558 24.196C101.177 25.2213 100.257 25.7445 99.0553 25.7445C97.2452 25.7445 96.0291 24.5708 95.9725 22.4778H103.694V21.7284C103.694 17.8041 101.346 16.2698 98.8856 16.2698C95.8594 16.2698 93.8654 18.5748 93.8654 21.9122C93.8654 25.2849 95.8311 27.491 99.0341 27.491ZM95.9796 20.894C96.0644 19.3526 97.1816 18.0162 98.8998 18.0162C100.54 18.0162 101.615 19.2324 101.622 20.894H95.9796Z" fill="#1F2937"/>
    <path d="M109.662 27.2718L110.736 23.9698H115.962L117.036 27.2718H120.317L115.325 12.791H111.38L106.381 27.2718H109.662ZM111.514 21.5799L113.296 16.1001H113.409L115.191 21.5799H111.514Z" fill="#1F2937"/>
    <path d="M121.349 27.2718H124.411V22.5768H126.984C130.315 22.5768 132.252 20.59 132.252 17.6981C132.252 14.8203 130.35 12.791 127.062 12.791H121.349V27.2718ZM124.411 20.1233V15.294H126.475C128.243 15.294 129.098 16.2556 129.098 17.6981C129.098 19.1334 128.243 20.1233 126.489 20.1233H124.411Z" fill="#1F2937"/>
    <path d="M136.591 12.791H133.53V27.2718H136.591V12.791Z" fill="#1F2937"/>
    <path d="M140.731 27.2718H142.916V20.9576H149.068V19.0839H142.916V14.6718H149.711V12.791H140.731V27.2718Z" fill="#1F2937"/>
    <path d="M155.295 27.491C158.356 27.491 160.357 25.2496 160.357 21.891C160.357 18.5112 158.356 16.2698 155.295 16.2698C152.233 16.2698 150.232 18.5112 150.232 21.891C150.232 25.2496 152.233 27.491 155.295 27.491ZM155.302 25.7162C153.301 25.7162 152.367 23.9698 152.367 21.8839C152.367 19.8051 153.301 18.0374 155.302 18.0374C157.289 18.0374 158.222 19.8051 158.222 21.8839C158.222 23.9698 157.289 25.7162 155.302 25.7162Z" fill="#1F2937"/>
    <path d="M162.006 27.2718H164.12V20.6395C164.12 19.2183 165.216 18.193 166.715 18.193C167.154 18.193 167.648 18.2708 167.818 18.3203V16.2981C167.606 16.2698 167.189 16.2486 166.92 16.2486C165.647 16.2486 164.559 16.9698 164.163 18.1364H164.049V16.4112H162.006V27.2718Z" fill="#1F2937"/>
    <path d="M168.937 27.2718H171.051V20.5758C171.051 19.1122 172.076 18.094 173.278 18.094C174.452 18.094 175.265 18.8718 175.265 20.0526V27.2718H177.372V20.3496C177.372 19.0415 178.171 18.094 179.55 18.094C180.667 18.094 181.586 18.7162 181.586 20.1869V27.2718H183.7V19.989C183.7 17.5071 182.314 16.2698 180.349 16.2698C178.786 16.2698 177.612 17.0193 177.089 18.1789H176.976C176.502 16.991 175.505 16.2698 174.056 16.2698C172.62 16.2698 171.553 16.9839 171.1 18.1789H170.966V16.4112H168.937V27.2718Z" fill="#1F2937"/>
  </svg>
</template>

<script>
export default {
  name: "Logo"
};
</script>
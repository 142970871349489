<template>
  <div>
    <Navbar :title="$t('account.settings.title')"/>
    <div class="flex border-b mb-6 px-6">
      <router-link 
        :to="{ name: 'plan'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'plan'}"
      >
        {{$t('account.settings.headers.billingPlan')}}
      </router-link>   
      <router-link 
        :to="{ name: 'paymentMethod'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'information'}"
      >
        {{$t('account.settings.headers.billingInformation')}}
      </router-link>
      <router-link 
        :to="{ name: 'billingHistory'}"
        class="mr-6 p-4"
        :class="{'border-b-2 border-pink-800': tabSelected === 'history'}"
      >
        {{$t('account.settings.headers.billingHistory')}}
      </router-link>      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '@/components/Navbar';

export default {
  name: "BillingHeader",
  components: {
    Navbar
  },
  data: function() {
    return {
      tabSelected: 'billingPlan'
    };
  },
  computed: {
    ...mapGetters({
    })
  },
  async mounted() {
    this.$data.tabSelected = this.$route.path.split('/')[3];    
  }
}
</script>